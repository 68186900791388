@import '../config';

/*! purgecss start ignore */
.bot-frame {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;

        .text {
            padding-top: $spacing-m;
            h1 {
                max-width: none;
            }
            h4 {
                &::before {
                    content: '';
                    display: block;
                    height: 2px;
                    width: 50%;
                    background-color: var(--bot-accent);
                    margin: $spacing-l 0;
                }
            }
        }

        .widget {
            padding-top: $spacing-m;
            width: 100%;

            #docsbot-widget-embed {
                height: 650px;
            }
        }
    }
    p {
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    @include breakpoint(large) {
        &__wrapper {
            flex-wrap: nowrap;
            column-gap: $spacing-l;

            .text {
                max-width: 60%;
            } 

            .widget {
                max-width: 40%;
            }
        }
    }
}


/*! purgecss end ignore */
