@import '../config';

$control-size: rem-calc(50);

.video {
  text-align: center;
  width: 100%;

  @include breakpoint(medium) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  video {
    margin: auto;
  }

  &-inverted {
    color: $color-white;
  }

  &.wrapped-inside {
    @include breakpoint(medium) {
      max-width: 70%;
      margin-left: 0;
      margin-bottom: $spacing-l;
      > div {
        z-index: 1;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: -42%;
        top: 25%;
        width: 80%;
        height: 80%;
        background-color: $color-light-gray;
        z-index: 0;
      }
    }
  }
}

/* Custom plyr styles */

/*! purgecss start ignore */

$plyr-color: '#4f5b5f';

.plyr {
  &__badge {
    background: $plyr-color;
  }

  &__control {
    svg {
      fill: $color-white;
      width: 18px;
    }

    &--overlaid {
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: $color-text;
      padding: 30px;

      svg {
        fill: $color-text;
      }
    }

    &.plyr__tab-focus {
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
    }
  }

  &--video {
    .plyr__control {
      svg {
        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
      }

      .plyr__tab-focus,
      &:hover,
      &[aria-expanded='true'] {
        background: rgba(255, 255, 255, 0);
        color: $color-white;
        opacity: 1;
      }

      &--overlaid {
        &:focus,
        &:hover {
          background: $color-white;
        }
      }
    }

    .plyr__controls {
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      color: #fff;
    }
  }

  &--hide-controls,
  &--stopped {
    .plyr__controls {
      opacity: 0;
      pointer-events: none;
      transform: translateY(100%);
    }
  }

  &--paused:not(.plyr--stopped) {
    .plyr__control--overlaid {
      opacity: 0;
      pointer-events: none;
      transform: translateY(100%);
    }
  }

  &--full-ui input[type='range'] {
    color: $color-white;
  }

  &--full-ui {
    .plyr__video-embed > .plyr__video-embed__container {
      //transform: translateY(-38.282%) !important;
    }
  }

  &__video-wrapper {
    background: $color-light-gray;
  }

  &__volume {
    flex-grow: 0;
  }

  &__poster {
    background-color: $color-white;
  }

  [type='button'].plyr__control--overlaid {
    border-radius: 100%;
  }
}

.bgVideo {
  position: relative;
  background: $color-text;

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-text;
    opacity: 0.3;
    z-index: 9;
  }

  &__headline {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;

    h1 {
      color: $color-white;
      margin-left: $spacing-l;
      margin-right: $spacing-l;

      @include breakpoint(large) {
        margin-left: $spacing-xxl * 2;
      }

      @include breakpoint(xlarge) {
        font-size: $font-size-title-xl * 2;
        line-height: 1.1;
      }
    }
  }

  @include breakpoint(small only) {
    min-height: 90vh;
    overflow-x: hidden;
    text-align: center;
    &__video {
      height: 100%;
      width: auto;
      max-width: none;
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.page-mag {
  .plyr {
    &__video-wrapper,
    &__poster {
      background-color: $color-text;
    }
  }
}

.page-wnc {
  .plyr {
    &--video,
    &__video-wrapper,
    &__poster {
      background-color: $color-white;
    }

    /* Fix for render bug */
    &--video {
      &::before,
      &::after {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        background: $color-white;
        z-index: 5;
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }
  }
}

.page-sfo {
  .bgVideo {
    &__headline {
      h1 {
        @include breakpoint(large) {
          margin-left: $spacing-xxl * 2;
        }

        @include breakpoint(xlarge) {
          font-size: $font-size-title-xl;
          line-height: 1.1;
        }
      }
    }
  }
}

/* hide related videos */
.plyr:not(.plyr--ended) iframe[id^='youtube'] {
  top: -50%;
  height: 200%;
}

.stream {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__live,
  &__chat {
    width: 100%;
  }

  &__chat {
    > div {
      padding: 56.25% 0 0 0;
      position: relative;
      min-height: rem(350);
    }
  }

  @include breakpoint(large) {
    &__wrapper {
      flex-wrap: nowrap;
    }

    &__chat {
      width: 40%;

      > div {
        padding: 100% 0 0;
        height: 100%;
      }
    }
  }
}

/*! purgecss end ignore */
