.icon {
  display: block;
  vertical-align: middle;
  pointer-events: none;
  position: relative;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;

  &-arrow {
    width: rem(32);
    height: rem(17);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31.663' height='17'%3E%3Cg data-name='arrow long'%3E%3Cpath data-name='Pfad 7772' d='M27.48 7.407l-5.862-5.862L23.163 0l8.5 8.5-8.5 8.5-1.545-1.545 5.862-5.862H0V7.407z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  }
}
