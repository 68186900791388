@import '../config';

/*! purgecss start ignore */
.grid {
  position: relative;
  display: flex;

  &--team {
    flex-wrap: wrap;

    .grid__item {
      flex-basis: calc(50% - 5px);
      margin: 0 $spacing-xxs $spacing-xs;

      @include breakpoint(small only) {
        &:nth-child(2n + 1) {
          margin-left: 0;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }

      .card {
        height: 100%;
        margin-bottom: 0;
        border: none;

        &__content {
          padding: $spacing-s $spacing-s $spacing-xxs $spacing-s;
          line-height: $line-height-normal;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          svg {
            transform: scale(0.8);
          }

          span.light {
            color: $color-text-faded;
            font-size: $font-size-s;
            display: block;
            margin-bottom: $spacing-s;
            flex-basis: 100%;
          }

          a {
            text-decoration: underline;
          }
        }
      }

      @include breakpoint(medium only) {
        flex-basis: calc(33.33% - 10px);

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }

      @include breakpoint(large) {
        flex-basis: calc(25% - 10px);

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        &:nth-child(4n + 4) {
          margin-right: 0;
        }
      }
    }
  }

  &--basic {
    flex-wrap: wrap;
    grid-gap: 0;

    .subhead {
      color: inherit;
      opacity: 0.8;
    }

    .grid__item {
      flex-basis: calc(50% - 5px);
      margin: 0 $spacing-xxs $spacing-s;

      p a {
        text-decoration: underline;
        font-weight: $font-weight-medium;
      }

      @include breakpoint(small only) {
        &:nth-child(2n + 1) {
          margin-left: 0;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }

      @include breakpoint(medium) {
        flex-basis: calc(33.33% - 10px);

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }
  }

  &--icons {
    flex-wrap: wrap;
    grid-gap: 0;

    .grid__item {
      display: flex;
      margin: 0 $spacing-xs $spacing-m;

      &__icon {
        width: rem(80);
        flex-shrink: 0;
        margin-right: $spacing-m;
      }

      @include breakpoint(medium) {
        flex-basis: calc(50% - 20px);

        &:nth-child(2n + 1) {
          margin-left: 0;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }

      @include breakpoint(large) {
        flex-basis: calc(33.33% - 20px);

        &:nth-child(2n + 1) {
          margin-left: $spacing-xs;
        }

        &:nth-child(2n + 2) {
          margin-right: $spacing-xs;
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }


  }
}

.page-honw {
  @include breakpoint(small only) {
    .grid--basic .grid__item {
      min-width: 100%;
    }
  }
  @include breakpoint(large) {

    .grid--basic .grid__item {
      //flex-basis: calc(20% - 20px);
      flex: 1 0 0;
      margin: 0 $spacing-xs $spacing-xs;

      // &:nth-child(5n + 1) {
      //   margin-left: 0;
      // }

      // &:nth-child(5n + 5) {
      //   margin-right: 0;
      // }
    }
  }
}

.page-sfo {
  @include breakpoint(large) {
    /*.grid--team .grid__item {
      flex-basis: calc(33% - 10px);
      margin: 0 $spacing-xs $spacing-s;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

    }*/
  }
}

/*! purgecss end ignore */
