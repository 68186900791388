@import '../config';
@import '../common/mixins';

$logo-height-large: 52px;
$logo-width-large: 180px;
$logo-width-small: 130px;
$brand-size: 18px;
$brand-barheight: 2px;
$honw-logo-width-large: 220px;
$mag-logo-width-large: 200px;
$wnc-logo-width-large: 180px;
$tnx-logo-width: 90px;
$tnx-logo-width-large: 150px;

.brand {
  display: flex;
  align-items: center;
  &__image {
    position: relative;
    width: $brand-size;
    height: $brand-size;
    transform: translateZ(0) rotate(-45deg);
    backface-visibility: hidden;

    &__wrapper {
      width: $brand-size;
      height: $brand-size;
      i {
        display: block;
        height: $brand-barheight;
        width: $brand-size;
        background-color: $color-text;
        position: absolute;
        left: 0;
        box-shadow: 0 0 0 0 $color-brand-yellow;
        transition: transform 0.25s ease-out, box-shadox 0.25s ease-out;

        &.brand__image {
          &--1 {
            top: 0;
          }
          &--2 {
            top: $brand-barheight * 2;
          }
          &--3 {
            top: $brand-barheight * 4;
            width: $brand-size - 2;
          }
          &--4 {
            top: $brand-barheight * 6;
          }
          &--5 {
            top: $brand-barheight * 8;
          }
        }
      }
    }

    .bot & {
      .brand__image__wrapper i {
        background-color:$color-white;
        box-shadow: 0 0 0 0 var(--bot-accent);
      }
    }
  }

  .brand__typo {
    width: 150px;
    height: $brand-size;

    img, svg {
      position: absolute;
      right: 0;
      top: 3px;
    }

    .bot & {
      svg path {
        fill: $color-white;
      }
    }
  }

  &:hover {
    i {
      box-shadow: 2px -2px 0 0 $color-brand-yellow;
      
      .bot & {
        box-shadow: 2px -2px 0 0 var(--bot-accent);

      }
    }
    .brand {
      &__image {
        &--1 {
          transform: translateY(-4px);
        }
        &--2 {
          transform: translateY(-2px);
        }
        &--4 {
          transform: translateY(2px);
        }
        &--5 {
          transform: translateY(4px);
        }
      }
    }

    .bot & {
      .brand__image__wrapper i {
        background-color:$color-text;
      }
    }
  }
}
/*! purgecss start ignore */

.header {
  position: relative;
  width: 100%;
  z-index: 30;
  background: $color-white;
  max-width: $max-page-width;
  margin: 0 auto;
  padding: $spacing-l 0;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: $content-width;
    margin: 0 auto;
  }

  h1 {
    font-family: $font-primary;
    margin: 0 auto 0 0;
    padding: 0;
    position: relative;
    display: flex;
    > a {
      display: flex;
    }

    span {
      text-indent: -99999em;
      width: 0;
    }
  }

  &__menu {
    display: none;
    border: 1px solid transparent;

    li {
      &.btn {
        padding-left: $spacing-s;
        padding-right: $spacing-s;

        a {
          color: #fff;
          text-transform: none;
          letter-spacing: initial;
        }

        &:hover {
          a {
            color: #fff;
          }
        }
      }
    }

    &--reduced {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      font-size: $font-size-s;
      margin: 0;

      li {
        padding: rem(2) 0;
        margin: 0 $spacing-s;
        position: relative;
        overflow: hidden;

        a {
          display: flex;
          align-items: center;
          font-weight: 600;
          svg {
            //margin-left: $spacing-xs;
            fill: $color-white;
            height: 30px;
            width: 30px;
          }
          &:focus {
            color: $color-white;
          }
        }

        &.no-underline {
          opacity: 0;
          @include breakpoint(large) {
            opacity: 1;
          }
        }

        &:not(.no-underline) {
          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 1px;
            left: 50%;
            bottom: 0;
            background: $color-white;
            transition: height 0.3s ease, width 0.3s ease 0s, left 0.3s ease 0s;
          }
        }

        &:hover,
        &.is-active {
          a {
            color: $color-white;
          }

          &::after {
            width: 100%;
            left: 0;
            right: auto;
          }
        }

        &.is-active {
          font-weight: $font-weight-medium;

          &::after {
            width: 100%;
            height: 2px;
          }
        }

        &.external {
          a {
            color: $color-brand-tnx;
          }
        }
      }
    }
  }

  &__sticky {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
    max-width: $max-page-width;
    width: 100%;
    z-index: $zindex-level-3;
  }

  &__menu-toggle,
  &__wabutton {
    position: absolute;
    right: $spacing-xs;
    top: $spacing-xs;
    width: 60px;
    height: 60px;
    transition: top 0.3s;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: $color-white;
      width: 100%;
      height: 100%;
      transition: background-color 0.3s ease-out;
    }

    &:hover {
      &::before {
      }
    }
  }

  &__menu-toggle {
    right: 70px;

    .bot & {
      right: $spacing-s;
    }
  }

  &__wabutton {
    svg {
      position: absolute;
      width: 32px;
      height: 32px;
      left: 14px;
      top: 14px;
      transition: transform 0.3s;
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }

  &.is-open {
    .header {
      &__menu-toggle,
      &__wabutton {
        &::before {
          background-color: $color-white;
        }
      }
    }
  }

  &.bar-active {
    .header__menu-toggle,
    .header__wabutton {
      top: rem-calc(46);

      .bot & {
        top: $spacing-xs;
      }
    }

    &.out-of-view {
      .header__menu-toggle,
      .header__wabutton {
        top: $spacing-xs;
      }
    }
  }

  @include breakpoint(large) {
    padding-left: $spacing-m;
    padding-right: $spacing-m;

    &__wrapper {
      padding: 0 $spacing-s;
    }

    h1 {
      height: $logo-height-large;
      width: $logo-width-large;
      margin: $spacing-m auto $spacing-m 0;
    }

    &__menu-toggle,
    &__wabutton {
      transition: transform 0.3s ease-out;
      transform: scale(0);
      transform-origin: center center;
      right: $spacing-xl;
      top: $spacing-xl;
      z-index: $zindex-level-2;
    }

    &__menu-toggle {
      right: rem(100px);
    }

    &__menu {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      font-size: $font-size-s;
      margin: 0;

      li {
        padding: rem(2) 0;
        margin: 0 $spacing-s;
        position: relative;
        overflow: hidden;

        a {
          display: flex;
          align-items: center;
          svg {
            //margin-left: $spacing-xs;
          }
        }

        &:not(.no-underline)::after {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          left: 50%;
          bottom: 0;
          background: $color-text;
          transition: height 0.3s ease, width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &.no-underline {
          .wa-trigger {
            transition: transform .3s ease-in-out;
            transform-origin: center center;
          }
          &:hover {
            .wa-trigger {
              transform: scale(1.1);
            }
          }
        }

        &:hover,
        &.is-active {
          a {
            color: $color-text;
          }

          &::after {
            width: 100%;
            left: 0;
            right: auto;
          }
        }

        &.is-active {
          font-weight: $font-weight-medium;

          &::after {
            width: 100%;
            height: 2px;
          }
        }
      }
    }

    &.out-of-view {
      .header__menu-toggle,
      .header__wabutton {
        transform: scale(1);
        transform-origin: center center;
      }
    }

    &.is-open {
      height: auto;

      .header__menu-toggle,
      .header__wabutton {
        transform: scale(1);
      }
    }

    &.bar-active {
      .header__menu-toggle,
      .header__wabutton {
        top: $spacing-xs;
      }
    }
  }

  &--inverted {
    color: $color-white;
    background: $color-text;
    .header {
      @include breakpoint(large) {
        &__menu {
          li {
            a {
              color: $color-white;
            }

            &:not(.no-underline)::after {
              background: $color-white;
              transition: height 0.3s ease, width 0.3s ease 0s, left 0.3s ease 0s;
            }

            &:hover,
            &.is-active {
              a {
                color: $color-white;
              }
            }

          }
        }
      }
    }
  }
}

.page-honw {
  .brand {
    width: $logo-width-small;
    @include breakpoint(large) {
      width: $honw-logo-width-large;
    }
  }

  .header {
    background: $color-brand-honw;
  }
}

.page-mag {
  .brand {
    width: $logo-width-small;
    @include breakpoint(large) {
      width: $mag-logo-width-large;
    }
  }
}

.page-wnc {
  .brand {
    width: $logo-width-small;
    @include breakpoint(large) {
      width: $wnc-logo-width-large;
    }
  }
}

.page-sfo {
  .brand {
    width: $logo-width-small;
    @include breakpoint(large) {
      width: $wnc-logo-width-large;
    }
  }
}

.page-tnx {
  .brand {
    width: $tnx-logo-width;
    @include breakpoint(large) {
      width: $tnx-logo-width-large;
    }
  }

  .header {
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
    max-width: 100%;
    padding-top: $spacing-s;
    padding-bottom: $spacing-s;
  }
}

/*! purgecss end ignore */

@keyframes underline-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes underline-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

/*! purgecss start ignore */
.announcement {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: $max-page-width;
  margin: 0 auto;
  padding: $spacing-xs $spacing-xs;
  background: $color-brand-blue;
  color: $color-white;
  text-align: center;
  font-weight: $font-weight-medium;
  font-size: $font-size-s;

  .bot & {
    display: none;
  }

  p {
    font-size: $font-size-s;
    margin: 0;
  }

  a {
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: $color-white;
      text-decoration: none;
    }
  }

  .page-tnx & {
    max-width: none;
  }
}

/*! purgecss end ignore */
