@import '../config';
$icon-size-small: 60px;
$icon-size-large: 120px;

/*! purgecss start ignore */
.roles {
  &__images {
    display: flex;
    width: 100%;
  }

  .accordion {
    input {
      &:checked {
        + .accordion__item__label {
          background: $color-brand-pink;
          color: $color-white !important;

          .accordion__item__label__text > span {
            svg {
              transform: rotate(180deg);
              > path {
                fill: $color-white;
              }
            }
          }

          .accordion__item__icon {
            svg > path {
              fill: $color-white;
            }
          }
        }
      }
    }

    &__item {
      border: none;
      &__label {
        position: relative;
        display: flex;
        align-items: center;
        transition: background-color 0.5s;
        padding: 0;
        &__text {
          z-index: 2;
          transition: color 0.5s $ease-in-out-quint;
          display: flex;
          align-items: center;
          font-size: $font-size-m;
          span {
            display: flex;
            align-items: center;
            width: 24px;
            height: 24px;
            position: relative;
            margin-left: $spacing-xs;
            svg {
              transition: transform 0.3s;
              transform-origin: center center;
              > path {
                fill: $color-text;
                transition: fill 0.3s;
              }
            }
          }
          @include breakpoint(large) {
            font-size: $font-size-title-xl;
            span {
              width: 48px;
              height: 48px;
              top: 3px;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        &::after {
          display: none;
        }
      }

      &__content {
        max-width: 100%;
        color: $color-text;

        a.btn {
          text-decoration: none;
        }

        .subhead {
          color: $color-text;
        }

        @include breakpoint(large) {
          &:not(.kreativer) {
            .subhead + .accordion__item__content__text {
              max-width: 80%;
            }
          }
          &.split {
            position: relative;
            display: grid;
            align-items: center;
            grid-template-columns: repeat(12, 1fr);
            .accordion__item__content__text {
              grid-column: 6 / span 6;
              grid-row: 1;
              order: 2;
              width: 100%;
            }
            .roles__images {
              grid-column: 1 / span 4;
              grid-row: 1;
              order: 1;
              &__item,
              &__item img {
                width: 100%;
              }
            }
          }
        }
      }

      &__effect {
        background-color: $color-brand-pink;
        transition: width 0.5s $ease-in-out-quint;
        height: 100%;
        width: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }

      &:hover {
        .accordion__item {
          &__icon {
            svg > path {
              fill: $color-white;
            }
          }
          &__label {
            transform: none;
            &__text {
              color: $color-white;
              > span svg > path {
                fill: $color-white;
              }
            }
          }
          &__effect {
            width: 100%;
          }
        }
      }

      &__icon {
        background: $color-brand-pink;
        width: $icon-size-small;
        height: $icon-size-small;
        z-index: 2;
        margin: 0;
        svg {
          height: $icon-size-small;
          width: $icon-size-small;
          > path {
            transition: fill 0.3s;
          }
        }

        @include breakpoint(large) {
          width: $icon-size-large;
          height: $icon-size-large;
          margin: 0;
          svg {
            height: $icon-size-large;
            width: $icon-size-large;
          }
        }
      }

      &:nth-child(odd) {
        .accordion__item {
          &__label {
            &__text {
              order: 2;
              flex-grow: 1;
              padding-left: 20px;
              color: $color-text;
              @include breakpoint(large) {
                padding-left: rem-calc(30);
              }
            }
          }
          &__icon {
            order: 1;
          }
        }
        &:hover {
          .accordion__item {
            &__label {
              &__text {
                color: $color-white;
              }
            }
            &__icon {
              order: 1;
            }
          }
        }
      }
      &:nth-child(even) {
        background: $color-text;
        .accordion__item {
          &__label {
            &__text {
              padding-left: 80px;
              color: $color-white;
              svg > path {
                fill: $color-white;
              }
              @include breakpoint(large) {
                padding-left: rem-calc(150);
              }
            }
          }
        }
      }
    }
  }
}

/*! purgecss end ignore */
