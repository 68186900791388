@import '../config';

/*! purgecss start ignore */

.flipCard {
    color: $color-white;
    width: 100%;
    min-width: rem-calc(300);
    margin: $spacing-xs 0;
    height: rem-calc(350);
    background-color: transparent;
    perspective: 1000px;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    &__image {
        width: rem-calc(80);
        height: rem-calc(80);
        padding: $spacing-xs;
        margin-bottom: $spacing-m;
        border: 1px solid $color-white;
        display: flex;
        align-items: center;

        > div {
            width: 100%;
        }

    }

    &__front,
    &__back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
        background-size: cover;
        background-position: center center;
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);

        > div {
            
        width: 100%;
        height: 100%;
            padding: $spacing-m;
            position: relative;

            .swiper-slide & {
                width: calc(100% - #{$spacing-m*2});
                height: calc(100% - #{$spacing-m*2});
            }
        }
    }

    &__front {
       
        > div {
            display: flex;
            flex-direction: column;
        }

        h2, h3, h4 {
            font-style: italic;
        }

        h3 {
            font-size: $font-size-title-m;
        }
        h4 {
            font-weight: $font-weight-normal;
        }

        .arrow {
            margin-top: auto;
            margin-left: auto;
            margin-right: $spacing-s;
            width: rem-calc(60);
            transition: transform .3s;
        }

        &:hover {
            .arrow {
                transform: translateX(10px);
            }
        }
    } 

    &__back {
        transform: rotateY(180deg) translateZ(1px);
        background: $color-white;
        color: $color-text;

        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    &:hover {
        cursor: pointer;
    }

    &.is-active {
        .flipCard__inner {
            transform: rotateY(180deg);
        }
    } 

    @include breakpoint(large) {
        width: calc(33.33% - #{$spacing-m});
        margin: 0 $spacing-xs;
        height: rem-calc(450);

        &__wrapper {
            margin-left: -1 * $spacing-xs;
            margin-right: -1 * $spacing-xs;
        }

        &__front {
            h3 {
                font-size: $font-size-title-l;
            }
        }

        .swiper-slide & {
            width: auto;
            overflow: hidden;
            margin: 0;
            height: 500px;
        }
    }

}

/*! purgecss end ignore */
