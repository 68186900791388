@import '../config';

/*! purgecss start ignore */
#socialFeed {
  opacity: 0;
  visibility: hidden;
  transition: opacity 3s;

  &.is-active {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
}
.page-mag .juicer-feed {
  text-align: center;

  .j-paginate {
    text-transform: uppercase;
    border-radius: 0;
    display: inline-block;
    width: auto;
    background: $color-brand-honw;
    color: $color-white;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    letter-spacing: 2px;
    text-align: center;
    padding: rem(13) rem(50);
    transition: background-color 0.3s;

    &:hover {
      cursor: pointer;
      color: $color-white;
      background: darken($color-brand-honw, 15%);
    }
  }

  p {
    line-height: 1.3;
    font-size: $font-size-s;
  }

  &.image-grid {

    li.feed-item {
      &.words {
        border: none;
        font-family: $font-primary;
      }
      &.j-video a.j-image {
        &::before {
          content: url("data:image/svg+xml,%3Csvg data-name='Icon - Play' xmlns='http://www.w3.org/2000/svg' width='88' height='88'%3E%3Ccircle data-name='Ellipse 22809' cx='44' cy='44' r='44' fill='%23fff' opacity='.9'/%3E%3Cg data-name='Pfad 7235' fill='%23333'%3E%3Cpath d='M39.072 52.371V36.262l12.753 8.055-12.753 8.054Z'/%3E%3Cpath d='m50.889 44.317-11.317-7.148v14.295l11.317-7.147m1.873 0-14.19 8.961V35.355l14.19 8.962Z'/%3E%3C/g%3E%3C/svg%3E");
          pointer-events: none;
        }
        &.playing::before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80'%3E%3Ccircle data-name='Ellipse 22809' cx='40' cy='40' r='40' fill='%23fff' opacity='.9'/%3E%3Cg data-name='Gruppe 1' fill='%23333'%3E%3Cpath data-name='Rechteck 1' d='M42 31h5v18h-5z'/%3E%3Cpath data-name='Rechteck 2' d='M33 31h5v18h-5z'/%3E%3C/g%3E%3C/svg%3E");
        }
      }

      &.j-youtube {
        .j-meta .j-youtube {
          color: $color-white;
        }
        .j-text,
        .j-meta {
          display: block;
        }

        .j-message {
          display: inline-block;
          vertical-align: middle !important;
          max-height: 90%;
          overflow: hidden;
          width: 100%;
          color: #fff;
          p {
            color: #fff;
          }
        }
      }

      .j-text {
        background-color: transparent;
        transition: background-color .3s $ease-in-out-cubic;
        font-family: "Public Sans", "Helvetica Neue", Arial, sans-serif;
        color: #fff;
        visibility: visible;
        opacity: 1;

        .j-message {
          opacity: 0;
          visibility: hidden;
          transition: opacity .3s;
          strong {
            font-weight: $font-weight-medium;
          }

          .j-title {
            margin-bottom: 5px;
          }
          .j-message {
            color: #fff;
            p, a {
              color: #fff;
            }
          }
        }

        .j-meta {
          margin-bottom: 6px;
          nav > a {
            background: $color-brand-pink;
            padding: 3px 0 3px 5px;
            opacity: 0;
            transition: opacity .3s;
            transition-delay: 1s;
            &::before {
              transition: color .3s;
              color: #fff;
            }
            &:hover {
              background: $color-white;
              &::before {
                color: $color-brand-pink;
              }
            }
          }
        }

        .j-read-more {
          font-weight: $font-weight-medium;
        }
      }

      &:hover {
        .j-text {
          pointer-events: none;
          background-color: $color-brand-pink;
          .j-message {
            opacity: 1;
            visibility: visible;
          }
        }
        &.j-video a.j-image {
          &::before,
          &.playing::before {
            opacity: 0;
          }
        }
      }
    }

    &.j-initialized {
      li.feed-item .j-text .j-meta nav > a {
        opacity: 1;
      }
    }
  }
}

.juicer-feed.image-grid .j-text .j-message p, .juicer-feed.image-grid .j-text .j-edit p, .juicer-feed.slider .j-text .j-message p, .juicer-feed.slider .j-text .j-edit p, .juicer-feed.hip .j-text .j-message p, .juicer-feed.hip .j-text .j-edit p {
  color: #fff !important;
}

.juicer-feed.image-grid .j-text .j-message a, .juicer-feed.image-grid .j-text .j-edit a, .juicer-feed.slider .j-text .j-message a, .juicer-feed.slider .j-text .j-edit a, .juicer-feed.hip .j-text .j-message a, .juicer-feed.hip .j-text .j-edit a {
  color: #fff !important;
  text-decoration: underline !important;
}

/*! purgecss end ignore */
