@import '../config';
@import '../common/mixins';

@mixin dropdown() {
  height: rem(60);
  border: 1px solid $color-lines;
  border-radius: $border-radius-m;
  background: url('../../img/chevron-down.svg') no-repeat;
  background-position: right -1rem center;
  background-size: rem(12) rem(12);
  background-origin: content-box;
  padding: 0 rem(40) 0 rem(18);
}

/*! purgecss start ignore */
.form {
  input,
  select,
  textarea {
    margin-bottom: 3px;
    &::after,
    &::before {
      box-sizing: border-box;
    }
  }

  .fui-field,
  .fui-field:not(.fui-type-hidden-field) {
    margin-bottom: 0;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  input,
  textarea {
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: transparent;
    transition: all 0.28s ease;
    box-shadow: none;
  }

  .button-container {
    text-align: center;
  }

  fieldset {
    margin: 0 0 3rem;
    padding: 0;
    border: none;
  }

  .form-radio,
  .form-group {
    position: relative;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .form-help {
    margin-top: 0.125rem;
    margin-left: 0.125rem;
    color: #b3b3b3;
    font-size: 0.8rem;
  }

  .checkbox .form-help,
  .form-radio .form-help,
  .form-group .form-help {
    position: absolute;
    width: 100%;
  }

  .checkbox .form-help {
    position: relative;
    margin-bottom: 1rem;
  }

  .form-radio .form-help {
    padding-top: 0.25rem;
    margin-top: -1rem;
  }

  .form-group {
    input {
      height: 1.9rem;
      &:focus,
      &:valid,
      &.form-file,
      &.has-value {
        color: $color-white;
      }
      &:focus ~ .control-label,
      &:valid ~ .control-label,
      &.form-file ~ .control-label,
      &.has-value ~ .control-label {
        font-size: 0.8rem;
        color: gray;
        top: -1rem;
        left: 0;
      }
      &[type='file'] {
        line-height: 1;

        ~ .bar {
          display: none;
        }
      }
    }

    textarea {
      //resize: none;
      min-height: rem(60);
      line-height: 1.2;
      &:focus,
      &:valid,
      &.form-file,
      &.has-value {
        color: $color-white;

        ~ .control-label {
          font-size: 0.8rem;
          color: gray;
          top: -1rem;
          left: 0;
        }
      }
    }

    select,
    select.fui-select {
      width: 100%;
      font-size: 1rem;
      height: 1.6rem;
      padding: 0.125rem 0.125rem 0.0625rem;
      border: none;
      line-height: 1.6;
      box-shadow: none;
      color: $color-white;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
      background-repeat: no-repeat, repeat;
      background-position: right 0 top 50%, 0 0;
      background-size: rem(24) rem(24);

      /* Hide arrow icon in IE browsers */
      &::-ms-expand {
        display: none;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      ~ .control-label {
        font-size: 0.8rem;
        color: gray;
        top: -1rem;
        left: 0;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .control-label {
      position: absolute;
      top: 0.25rem;
      pointer-events: none;
      padding-left: 0.125rem;
      z-index: 1;
      color: #b3b3b3;
      font-size: 1rem;
      font-weight: normal;
      transition: all 0.28s ease;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .bar {
      position: relative;
      border-bottom: 0.0625rem solid #999;
      display: block;

      &::before {
        content: '';
        height: 0.125rem;
        width: 0;
        left: 50%;
        bottom: -0.0625rem;
        position: absolute;
        background: $color-text-faded;
        transition: left 0.28s ease, width 0.28s ease;
        z-index: 2;
      }
    }

    input:focus,
    textarea:focus {
      outline: none;
      background: none;
      box-shadow: none;
      border: none;
    }

    select:focus ~ .control-label,
    input:focus ~ .control-label,
    textarea:focus ~ .control-label {
      color: $color-white;
    }

    select:focus ~ .bar::before,
    input:focus ~ .bar::before,
    textarea:focus ~ .bar::before {
      width: 100%;
      left: 0;
    }
  }

  .form-inline > {
    .form-group,
    .btn {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  .checkbox label,
  .form-radio label {
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
    text-align: left;
    color: $color-white;
    display: block;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .checkbox input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
  }

  .radio {
    margin-bottom: 1rem;

    .helper {
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      cursor: pointer;
      display: block;
      font-size: 1rem;
      user-select: none;
      color: #999;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 0.25rem;
        width: 1rem;
        height: 1rem;
        transition: transform 0.28s ease;
        border-radius: 50%;
        border: 0.125rem solid currentColor;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 0.25rem;
        width: 1rem;
        height: 1rem;
        transition: transform 0.28s ease;
        border-radius: 50%;
        border: 0.125rem solid currentColor;
        transform: scale(0);
        background-color: $color-white;
      }
    }

    label:hover .helper {
      color: $color-white;
    }

    input:checked ~ .helper {
      &::after {
        transform: scale(0.5);
      }

      &::before {
        color: $color-white;
      }
    }
  }

  .checkbox {
    margin-top: 3rem;
    margin-bottom: 1rem;

    /* stylelint-disable-next-line no-descending-specificity */
    .helper {
      color: #999;
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      z-index: 0;
      border: 0.125rem solid currentColor;
      border-radius: 0.0625rem;
      transition: border-color 0.28s ease;

      &::before {
        top: 0.65rem;
        left: 0.38rem;
        transform: rotate(-135deg);
        box-shadow: 0 0 0 0.0625rem #fff;
      }

      &::after {
        top: 0.3rem;
        left: 0;
        transform: rotate(-45deg);
      }

      &::before,
      &::after {
        position: absolute;
        height: 0;
        width: 0.2rem;
        background-color: $color-white;
        display: block;
        transform-origin: left top;
        border-radius: 0.25rem;
        content: '';
        transition: opacity 0.28s ease, height 0s linear 0.28s;
        opacity: 0;
      }
    }

    label:hover .helper {
      color: $color-white;
    }

    input:checked ~ .helper {
      color: $color-white;

      &::after,
      &::before {
        opacity: 1;
        transition: height 0.28s ease;
      }

      &::after {
        height: 0.5rem;
      }

      &::before {
        height: 1.2rem;
        transition-delay: 0.28s;
      }
    }
  }

  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: 1rem;
  }

  .has-error {
    .legend.legend {
      color: #d9534f;
    }

    &.form-group {
      .control-label.control-label,
      .form-help,
      .helper {
        color: #d9534f;
      }
    }

    &.checkbox {
      .form-help,
      .helper {
        color: #d9534f;
      }
    }

    &.radio {
      .form-help,
      .helper {
        color: #d9534f;
      }
    }

    &.form-radio {
      .form-help,
      .helper {
        color: #d9534f;
      }
    }

    .bar::before {
      background: $color-text-faded;
      left: 0;
      width: 100%;
    }
  }

  .button {
    position: relative;
    background: currentColor;
    border: 1px solid currentColor;
    font-size: 1.1rem;
    color: #4f93ce;
    margin: 3rem 0;
    padding: 0.75rem 3rem;
    cursor: pointer;
    /* stylelint-disable-next-line value-list-comma-newline-after */
    transition: background-color 0.28s ease, color 0.28s ease,
      box-shadow 0.28s ease;
    overflow: hidden;

    span {
      color: #fff;
      position: relative;
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      background: #071017;
      border: 50vh solid #1d4567;
      width: 30vh;
      height: 30vh;
      border-radius: 50%;
      display: block;
      top: 50%;
      left: 50%;
      z-index: 0;
      opacity: 1;
      transform: translate(-50%, -50%) scale(0);
    }

    &:hover {
      color: $color-white;
    }

    &:active::before {
      transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }

    &:focus {
      &::before {
        transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
      }

      outline: none;
    }
  }

  @include breakpoint(medium) {
    .form-group {
      .control-label,
      input,
      textarea {
        font-size: $font-size-xl;
      }

      optgroup {
        font-size: $font-size-m;
      }

      input,
      select {
        height: rem(60);
      }
    }
  }
  &--light {
    .form-group {
      input {
        &:focus,
        &:valid,
        &.form-file,
        &.has-value {
          color: $color-text;
        }
      }

      textarea {
        &:focus,
        &:valid,
        &.form-file,
        &.has-value {
          color: $color-text;
        }
      }

      select {
        color: $color-text;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      .bar {
        &::before {
          background: $color-text-faded;
        }
      }

      select:focus ~ .control-label,
      input:focus ~ .control-label,
      textarea:focus ~ .control-label {
        color: $color-text;
      }
    }

    .checkbox label,
    .form-radio label {
      color: $color-text;
    }

    .radio {
      .helper {
        color: #999;

        &::after {
          background-color: $color-text;
        }
      }

      label:hover .helper {
        color: $color-text;
      }

      input:checked ~ .helper {
        &::before {
          color: $color-text;
        }
      }
    }

    .checkbox {
      /* stylelint-disable-next-line no-descending-specificity */
      .helper {
        &::before,
        &::after {
          background-color: $color-text;
        }
      }
      label:hover .helper {
        color: $color-text;
      }
      input:checked ~ .helper {
        color: $color-text;
      }
    }
  }
}

/*! purgecss end ignore */
