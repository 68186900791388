@import '../config';

/*! purgecss start ignore */
.accordion {
  overflow: hidden;

  &__item {
    width: 100%;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid $color-border;
    transition: height 0.3s;

    &__label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: $spacing-m $spacing-xl $spacing-m 0;
      margin-left: 0;
      font-size: $font-size-l;
      font-weight: $font-weight-medium;
      cursor: pointer;
      color: inherit;
      transition: transform 0.3s;

      &:hover {
        transform: translateX(10px);
        transition: transform 0.3s;
      }

      &::after {
        content: '+';
        width: 1em;
        height: 1em;
        font-weight: $font-weight-normal;
        text-align: center;
        transition: all 0.35s;
        position: absolute;
        right: $spacing-s;
        top: $spacing-m;
        flex-shrink: 0;
        font-size: $font-size-title-l;
      }
    }
    &__icon {
      width: rem-calc(160);
      flex-shrink: 0;
      margin: $spacing-xxs 0 $spacing-l $spacing-s;
    }

    &__content {
      max-height: 0;
      height: 0;
      padding: 0;
      color: inherit;
      background: white;
      transition: all 0.35s;

      a {
        text-decoration: underline;
      }

      &.extended {
        .item-image {
          margin-bottom: $spacing-m;
        }
      }
    }

    &.with-icon {
      .accordion__item {
        &__label {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          &__text {
            padding-left: $spacing-s;
          }
        }
        &__content {
          padding-left: $spacing-s;
          padding-right: $spacing-s;
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &[type='checkbox'] + label {
      margin-left: 0;
    }

    &:checked {
      + .accordion__item__label {
        &::after {
          content: '–';
        }
      }
      ~ .accordion__item__content {
        max-height: 300vh;
        height: auto;
      }
    }
  }

  &.inverted {
    background: $color-text;
    color: $color-white;
    border-top: 1px solid $color-text-faded;
    .accordion__item {
      border-bottom-color: $color-text-faded;
      &__label {
        color: $color-white;
      }
      &__content {
        background: $color-text;
        color: $color-white;
      }
    }
  }

  @include breakpoint(medium) {
    &__item {
      &__label {
        font-size: $font-size-title-xl;
        padding-right: rem(100);
        &.smaller {
          font-size: $font-size-title-l;
        }
      }

      &__icon {
        width: rem-calc(120);
        margin-bottom: 0;
        margin-right: $spacing-xl;
      }

      &.with-icon {
        .accordion__item {
          &__label {
            flex-wrap: nowrap;
          }
          &__content {
            padding-left: rem-calc(185);
          }
        }
      }
    }

    input {
      &:checked {
        ~ .accordion__item__content {
          //padding-right: rem(100);
        }
      }
    }
  }

  @include breakpoint(large) {
    &__item__label {
      font-size: $font-size-title-xl;
    }
    &__item__content {
      max-width: 90%;

      &.extended {
        display: flex;
        align-items: flex-start;
        .item-image {
          margin-bottom: $spacing-m;
          margin-right: $spacing-m;
          width: 30%;
        }
      }
    }
  }
}

.page-tnx .accordion {
  background: none;
  &__item {
    border-bottom-color: $color-text;
    &__content {
      background: none;
    }
  }
}

.page-mag .accordion:not(.accordion__styled),
.page-sfo .accordion {
  background: none;
  color: $color-white;
  .accordion__item {
    color: $color-white;
    border-bottom-color: $color-white;
    &__content {
      background: none;
      color: inherit;
    }
    &__label {
      color: inherit;
    }
  }
}

/*! purgecss end ignore */
