@import '../config';
@import '../common/mixins';

.questions {
  background: $color-dark-gray;
  color: $color-white;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-size: $font-size-l;
      border-bottom: 1px solid $color-border-dark;
      padding: $spacing-l 0;

      > a {
        span {
          display: block;
          color: $color-text-faded;
        }

        i {
          display: block;
          margin-top: $spacing-s;
          transition: opacity 0.3s;
        }

        &:focus {
          color: $color-white;
        }
      }

      &:hover {
        > a i {
          opacity: 0.5;
        }
      }
    }
  }

  &__large {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-brand-blue;
    text-align: center;
    color: $color-white;
    font-size: $font-size-title-m;
    font-weight: $font-weight-medium;
    font-style: italic;
    padding: $spacing-m;

    &__inner {
      text-align: center;
      @include breakpoint(large) {
        max-width: 75%;
      }
    }
  }

  &__pattern {
    background-image: url(../../img/pattern.svg);
    background-repeat: repeat;
    height: rem(450);
    width: 100%;
    display: flex;

    &__wrapper {
      background: $color-white;
      flex: 0 0 15px;
      min-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      > span {
        color: $color-text;
        font-size: 10px;
        transform: rotate(-90deg);
        white-space: nowrap;
      }
    }
  }

  @include breakpoint(medium) {
    &__list {
      font-size: $font-size-title-m;
      text-align: center;

      li {
        > a {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: rem-calc(20) rem(10);

          span {
            display: inline;
            justify-self: flex-start;
            font-size: $font-size-l;
          }

          i {
            display: inline;
            margin-top: 0;
          }
        }
      }
    }

    &__large {
      font-size: $font-size-title-l;
      &__inner {
        max-width: rem(600);
      }
    }
  }
}
