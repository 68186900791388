/**
 * The entry point for the css.
 *
 */

/**
 * Import the vendor.scss styles
 */
@import url('vendor.scss');

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */
@import 'common/mixins.scss';
@import 'common/global.scss';
@import 'common/typography.scss';
@import 'common/webfonts.scss';
@import 'common/404.scss';
@import 'config.scss';

@import 'common/animation.scss';
@import 'common/button.scss';
@import 'common/element-scale.scss';
@import 'common/forms.scss';
@import 'common/icon.scss';
@import 'common/images.scss';
@import 'common/pages.scss';
@import 'common/spacing.scss';
@import 'common/table.scss';

/* Import component classes */
@import 'components/accordion.scss';
@import 'components/botwidget.scss';
@import 'components/carousel.scss';
@import 'components/contact.scss';
@import 'components/cookies.scss';
@import 'components/countdown.scss';
@import 'components/counter.scss';
@import 'components/events.scss';
@import 'components/flipCard.scss';
@import 'components/footer.scss';
@import 'components/grid.scss';
@import 'components/hamburger.scss';
@import 'components/header.scss';
@import 'components/lists.scss';
@import 'components/image-text.scss';
@import 'components/imageCards.scss';
@import 'components/layouts.scss';
//@import 'components/loading-layer.scss';
@import 'components/maps.scss';
@import 'components/roles.scss';
@import 'components/socialfeed.scss';
@import 'components/timeline.scss';
@import 'components/nav-flyout.scss';
@import 'components/newsletter.scss';
@import 'components/video.scss';
@import 'components/questions.scss';
@import 'components/quote.scss';

/* import page transitions */
@import 'common/page-transition.scss';