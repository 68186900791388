@import '../config';

/*! purgecss start ignore */
.layout {
  position: relative;

  &__headline,
  &__copy,
  &__wrapper .imageText {
    z-index: 2;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    .imageText {
      flex: 1 0 50%;
    }

    .effect {
      display: none;
    }
  }

  h2 + .btn {
    margin-top: $spacing-small;
  }

  &__copy {
    > div {
      p a {
        text-decoration: underline;
      }
    }

    &__text.inverted {
      color: $color-white;
      .subhead {
        color: $color-white;
      }
    }
  }

  @include breakpoint(large) {
    &__headline {
      h1 {
        max-width: 80%;
      }
    }

    &__copy {
      display: grid;
      position: relative;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 20px;

      > div {
        grid-row: 1;
        grid-column: 1 / span 7;

        &.shift-4 {
          grid-column: 1 / span 9;
        }
      }
    }
  }

  &--intro {
    .layout {
      &__wrapper {
        flex-wrap: wrap;
        .effect,
        .imageText--top {
          display: none;
        }

        .layout__video {
          width: 100%;
        }
      }
    }
    @include breakpoint(medium) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 25%;
        width: 42%;
        height: 35%;
        background-color: $color-light-gray;
        z-index: 0;
      }

      &.shape-grey {
        &::after {
          background-color: $color-light-gray;
        }
      }

      .layout__wrapper {
        display: grid;
        position: relative;
        grid-template-columns: repeat(12, 1fr);

        .imageText {
          width: auto;
        }

        .imageText--top {
          grid-column: 7 / span 5;
          grid-row: 1;
          z-index: 2;
          padding-top: 50%;
          display: block;

          .imageText__image {
            box-shadow: 0 rem(20) rem(40) rgba(0, 0, 0, 0.15);
          }
        }

        .effect {
          display: block;
          position: absolute;
          bottom: -20%;
          left: 12.5%;
          z-index: 3;

          &--right {
            top: 30%;
            right: 15%;
            bottom: auto;
            left: auto;
          }
        }

        .imageText--bottom,
        .layout__video {
          grid-row: 1;
          grid-column: 1 / span 8;
          z-index: 1;
        }
      }
    }
    @include breakpoint(large) {
      &::after {
        height: 50%;
      }
    }
  }

  &--mag {
    border-bottom: 1px solid $color-text;
    .layout {
      &__wrapper {
        flex-wrap: wrap;
      }
    }
    @include breakpoint(small only) {
      .layout__copy {
        background: $color-brand-pink;
      }
    }
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      //overflow-x: hidden;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0%;
        width: 75%;
        height: 60%;
        background-color: $color-brand-pink;
        z-index: 0;
      }

      .layout__copy {
        order: 1;
        display: grid;
        position: relative;
        grid-template-columns: repeat(12, 1fr);

        &__text {
          grid-column: 6 / span 7;
          grid-row: 1;
          font-size: $font-size-l;

          .h1 {
            max-width: none;
          }
        }
      }

      .layout__wrapper {
        display: grid;
        position: relative;
        grid-template-columns: repeat(12, 1fr);
        order: 2;
        width: 100%;

        .imageText {
          width: auto;
        }

        .imageText--bottom {
          grid-column: 1 / span 5;
          grid-row: 1;
          z-index: 2;
          display: block;
          transform: translateY(-50%);
          margin-bottom: -50%;
        }

        .layout__video {
          grid-column: 1 / span 8;
          grid-row: 1;
          z-index: 2;
          display: block;
        }

        .effect {
          display: block;
          position: absolute;
          bottom: -20%;
          left: 12.5%;
          z-index: 3;
          svg > path {
            fill: $color-white;
          }

          &--right {
            top: 30%;
            left: 35%;
            bottom: auto;
          }
        }
      }
    }
  }
  &--hireMe {
    @include breakpoint(small only) {
      display: flex;
      flex-wrap: wrap;
      .layout {
        &__wrapper {
          order: 2;
          width: 100%;
        }
        &__copy {
          order: 1;
        }
        &__video {
          width: 100%;
        }
      }
    }
    @include breakpoint(medium) {
      .layout__wrapper {
        .effect {
          bottom: auto;
          top: -250px;
          left: 10%;
          svg > path {
            fill: $color-white;
          }
        }
        .imageText--bottom {
          transform: none;
          margin-bottom: 0;
          grid-column: 1 / span 8;
        }
      }
      .layout__copy {
        &__text {
          grid-column: 5 / span 8;
        }
      }
    }
  }
  &--basic {
    border-bottom: 1px solid $color-text;
    .layout {
      &__wrapper {
        flex-wrap: wrap;
      }
    }
    @include breakpoint(small only) {
      .layout__copy {
        background: $color-brand-blue;
      }
    }
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0%;
        width: 100%;
        height: 85%;
        background-color: $color-brand-blue;
        z-index: 0;
      }

      .layout__copy {
        order: 1;
        display: grid;
        position: relative;
        grid-template-columns: repeat(12, 1fr);

        &__text {
          grid-column: 7 / span 6;
          grid-row: 1;
          padding-top: $spacing-xl;
        }
      }

      .layout__wrapper {
        display: grid;
        position: relative;
        grid-template-columns: repeat(12, 1fr);
        order: 2;
        width: 100%;

        .imageText {
          width: auto;
        }

        .imageText--bottom {
          grid-column: 1 / span 5;
          grid-row: 1;
          z-index: 2;
          display: block;
          transform: translateY(-80%);
          margin-bottom: -80%;
        }
      }
    }
  }
  &--module {
    .layout {
      &__wrapper {
        flex-wrap: wrap;
      }
      &__copy {
        background: $_color-wild-sand;
        color: $color-text;
        padding: 0 $spacing-m;
      }
    }
    &[data-color='white'] .layout__copy {
      background: $color-white;
    }

    &[data-color='aquamarine'] .layout__copy,
    &[data-color='blue'] .layout__copy {
      background: $color-brand-aqua;
      color: $color-white;
      .subhead {
        color: $color-white;
      }
    }

    &[data-color='blue'] .layout__copy {
      background: $color-brand-blue;
    }

    &[data-color='codGrey'] .layout__copy {
      background: $color-text;
      color: $color-white;
      .subhead {
        color: $color-white;
      }
    }

    &[data-color='transparent'] .layout__copy {
      background: none;
      color: inherit;
      .subhead {
        color: inherit;
      }
    }

    @include breakpoint(large) {
      .layout {
        display: flex;
        flex-wrap: wrap;

        &__copy {
          order: 1;
          display: grid;
          position: relative;
          grid-column: 1 / span 8;
          grid-row: 1;
          z-index: 1;
          padding: 0;

          &__text {
            grid-column: 2 / span 9;

            .two-cols {
              column-count: 2;
              column-gap: 40px;
            }

            .h1 {
              max-width: none;
            }

            p a {
              text-decoration: underline;
            }
          }
        }

        &__wrapper {
          display: grid;
          position: relative;
          grid-template-columns: repeat(12, 1fr);
          order: 2;
          width: 100;

          .imageText {
            width: auto;
            grid-column: 8 / span 5;
            grid-row: 1;
            z-index: 3;
            display: block;
            margin-top: 25vh;

            .page-sfo & {
              margin-top: 5vh;
            }
          }

          &.top {
            align-items: flex-start;
          }
          &.center {
            align-items: center;
          }
          &.bottom {
            align-items: flex-end;
          }

          &.top,
          &.center,
          &.bottom {
            .imageText {
              margin-top: inherit;
            }
          }
        }
      }

      .effect {
        display: block;
        position: absolute;
        bottom: rem-calc(-50);
        left: 50%;
        z-index: 10;
      }

      &.image-left {
        .layout__wrapper {
          .imageText {
            grid-column: 1 / span 5;
          }
        }
        .layout__copy {
          grid-column: 3 / span 10;
          &__text {
            grid-column: 5 / span 7;
          }
        }
        .effect {
          left: 10%;
          bottom: auto;
          top: rem-calc(-40);
        }
      }
    }
  }
  &--combi {
    @include breakpoint(medium) {
      .layout {
        &__wrapper {
          > div {
            flex: 1 0 50%;
          }
        }

        &__copy {
          padding: $spacing-l;
        }
      }
    }
  }
  &--cases {
    .layout {
      &__wrapper {
        display: block;
        align-items: flex-end;
        justify-content: space-between;
        .layout__facts {
          display: flex;
          flex-wrap: wrap;
          &__item {
            padding: $spacing-m $spacing-m 0;
          }
        }
        @include breakpoint(medium) {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-end;
          justify-content: space-between;
          .imageText {
            flex: 1 0 60%;
          }
          .layout__facts {
            display: block;
            flex: 1 0 30%;
            &__item {
              padding: $spacing-m 0 0 $spacing-l;
            }
          }
        }
      }
    }
  }
}

.page-honw .layout {
  &__copy {
    > div {
      p > em {
        background: $color-brand-honw;
        color: $color-white;
      }
    }
  }
}

.page-mag .layout {
  &--module {
    @include breakpoint(large) {
      .layout__copy {
        height: 85%;
      }
      .layout__wrapper {
        .imageText {
          margin-top: 10vh;
        }
      }
    }
  }
}

.page-wnc .layout {
  &--module {
    &[data-color='white'] {
      background: $color-white;
      .layout__copy {
        //margin-top: 10vh;
      }
      .layout__wrapper {
        .imageText {
          margin-top: 0;
        }
      }
    }

    .effect svg path {
      fill: $color-text;
    }
  }

  &__copy {
    > div {
      p > em {
        background: $color-brand-aqua;
        color: $color-white;
      }
    }
  }
}

.page-sfo .layout {
  &__copy {
    > div {
      p > em {
        background: $color-brand-blue;
        color: $color-white;
      }
    }
  }
}

/*! purgecss end ignore */
