@import '../config';

/*! purgecss start ignore */
.imageCards {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $color-text;
    border-left: 1px solid $color-text;

    &__item {
        width: 50%;
        height: 400px;
        position: relative;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $color-text;
        border-right: 1px solid $color-text;
        padding: $spacing-m $spacing-m 0;
        transition: all .3s ease-in;

        &:nth-child(1n) {
            border-right: 1px solid $color-text;
        }

        .item {

            transition: all .3s ease-in;
            &-content {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                
                transition: all .3s ease-in;
            }
            &-image {
                max-width: 200px;
                max-height: 200px;
                overflow: hidden;
                // position: relative;
                // top: 50%;
                transform: translateY(1px);
                transition: all .3s ease-in;
            }
            &-text {
                opacity: 0;
                height: 0;
                max-height: 0;
                text-align: center;
                transition: all .3s ease-in;
                transform: translateY(40px);
                margin-top: $spacing-s;
                position: relative;
                
                p {            
                    font-size: $font-size-s;
                }

                a {
                    text-decoration: underline;
                }
            }
        }

        &:hover {
            cursor: pointer;
            transition: all .3s ease-in;

            .item {
                &-content {
                    transition: all .3s ease-in;
                }
                &-image {
                    position: relative;
                    transform: translateY(0);
                    transition: all .3s ease-in;
                }
                &-text {
                    opacity: 1;
                    transform: translateY(0);
                    height: 200px;
                    max-height: 200px;
                    transition: all .3s ease-in;
                }
            }

        }
    }

    @include breakpoint(medium) {
        &__item {
            width: 33.33%;
            &:nth-child(1n) {
                border-right: 1px solid $color-text;
            }

            .item-text p {            
                font-size: $font-size-m;
            }
        }
    }
    
    @include breakpoint(xlarge) {
        &__item {
            width: 25%;
        }
    }

    .page-mag & {
        border-color: $color-white; 
        color: $color-white;    
        &__item {
            color: $color-white;    
            border-color: $color-white; 
        }
    }
}

/*! purgecss end ignore */
