@import '../config';

table {
  thead,
  tbody,
  tfoot {
    background: $color-white;
    border: none;
  }

  tr,
  tr:nth-child(even) {
    background: $color-white;
    border-bottom: 1px solid $color-lines;
  }

  td,
  th {
    font-size: $font-size-s;
    padding-left: 0;
    font-weight: $font-weight-normal;

    &.bold {
      font-weight: $font-weight-medium;
    }

    &.inset-single {
      padding-left: $spacing-m;
    }

    &.inset-double {
      padding-left: $spacing-xl;
    }
  }
}
