@import '../config';

/*! purgecss start ignore */
.grecaptcha-badge {
  display: none;
}

.contact {
  position: relative;

  &.waiting {
    .form-group {
      opacity: 0.3;
    }
  }

  &__text-effect {
    background: $color-text;
    color: $color-white;
    text-align: center;

    &__title {
      position: relative;
      display: inline;
      font-size: $font-size-title-l;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      flex: 1 0 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__form {
    background: $color-text;
    color: $color-white;
    padding: $spacing-m;

    .form {
      position: relative;
      margin-bottom: $spacing-m;
      input,
      select,
      textarea {
        background-color: $color-text !important;
        color: $color-white !important;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 $color-text 30px inset !important;
          -webkit-text-fill-color: $color-white !important;
        }
      }
      select.fui-select,
      input.fui-input,
      textarea.fui-input {
        border-bottom: 0.0625rem solid #999;
        border-radius: 0;
      }
      select.fui-select {
        padding-bottom: 1rem;
        height: auto;
      }
      textarea.fui-input {
        padding-top: 0.5rem;
      }

      .btn {
        width: 100%;
        min-height: rem(50px);
        margin-left: $spacing-xs;
      }

      &__wrapper {
        position: relative;
        p {
          margin-bottom: 0;
        }
      }
    }
    .fui-alert {
      @include h4;
      background: none;
      padding: 0;
      &.fui-alert-success {
        background: none;
        color: $color-white;
      }
    }
    &__message {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      background: $color-text;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: flex-start;
      &.is-active {
        display: flex;
      }
      &.error {
        color: red;
      }
      > span {
        display: block;
        text-decoration: underline;
        margin-top: $spacing-s;
        &:hover {
          cursor: pointer;
          color: $color-white;
        }
      }
    }

    &__wrapper {
      p a:not(.contact__form__link) {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    &__link {
      position: relative;
      top: rem(7);
      left: rem(3);
      margin-right: rem(5);
      text-decoration: none;
      svg {
        fill: $color-white;
      }
    }
  }
  @include breakpoint(medium) {
    &__form {
      .form .btn {
        width: auto;
      }
    }
    &__text-effect {
      &__title {
        font-size: rem(44);
        &:nth-child(2n + 1) {
          display: block;
        }
      }
    }
  }

  @include breakpoint(large) {
    display: flex;

    > div {
      flex: 1 0 50%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__text-effect {
      &__title {
        font-size: rem(70);
        display: block;
      }
    }

    &__form {
      padding-left: $spacing-xl;
      padding-right: $spacing-xl;
      text-align: center;

      &__wrapper {
        max-width: 80%;
        width: 100%;
        margin: auto;
      }
    }
  }

  input#userRegistration {
    display: none !important;
  }
}

.page-honw,
.page-mag,
.page-wnc,
.page-sfo,
.page-tnx {
  .contact {
    &__text-effect {
      background: $color-brand-honw;
    }

    &__form {
      background: $color-light-gray;
      color: $color-text;
      width: 100%;

      .form {
        input,
        select,
        textarea {
          background-color: $color-light-gray !important;
          color: $color-text !important;
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active,
          &:focus {
            box-shadow: 0 0 0 $color-light-gray 30px inset !important;
            -webkit-text-fill-color: $color-text !important;

            + label {
              color: $color-text;
            }
          }
        }
        select.fui-select:after {
          border-bottom: 1px solid #fff;
        }
      }

      .fui-alert {
        &.fui-alert-success {
          color: $color-text;
        }
      }

      &__message {
        background: $color-light-gray;
        > span {
          &:hover {
            color: $color-text;
          }
        }
      }

      &__link {
        svg {
          fill: $color-text;
        }
      }
    }

    &__wrapper {
      position: relative;
      padding: $spacing-m;
      background: $color-light-gray;
    }
  }
}

.page-wnc,
.page-tnx {
  .contact {
    &__form {
      background: $color-white;

      .form {
        input,
        select,
        textarea {
          background-color: $color-white !important;
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active,
          &:focus {
            box-shadow: 0 0 0 $color-white 30px inset !important;
          }
        }
      }

      &__message {
        background: $color-white;
      }
    }
  }
}

.page-mag .contact {
  &__text-effect {
    background: $color-brand-pink;
  }
}

.page-sfo .contact {
  &__text-effect {
    background: $color-brand-blue;
  }
}

.page-wnc .contact {
  &__text-effect {
    background: $color-brand-aqua;
  }
}

.page-tnx .contact {
  &__text-effect {
    background: $color-brand-tnx;
  }
}

main.jobEntry,
main.hide-form,
.page-wnc main.detail,
//.page-mag main.detail,
.page-sfo main.detail,
.page-tnx main.detail,
.page-honw main.detail {
  .contact--footer {
    display: none;
  }
}

/* clean version for inline use */

.contact {
  &__form.clean {
    background: none;
    padding: 0;

    .form {
      input,
      select,
      textarea {
        background: none !important;
        color: currentColor !important;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 $color-text 30px inset !important;
          -webkit-text-fill-color: currentColor !important;
        }
        &:focus ~ .control-label {
          color: currentColor;
        }
      }
    }
  }
}

/*! purgecss end ignore */
