// Public Sans Variable

@font-face {
  font-family: 'PublicSansFont';
  src: url(../../fonts/PublicSans-VariableFont_wght.ttf)
    format('truetype-variations');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'PublicSansFont';
  src: url(../../fonts/PublicSans-Italic-VariableFont_wght.ttf)
    format('truetype-variations');
  font-weight: 100 900;
  font-style: italic;
}
