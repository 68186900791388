@import "../config";

.maps {
  width: 100%;
  background-color: $color-brand-honw;

  &__map {
    width: 100%;
    height: rem(400);
  }

  &__text {
    padding: $spacing-m;
    font-size: $font-size-l;
    color: $color-white;
    font-weight: $font-weight-medium;
    text-align: center;

    .subhead {
      color: $color-white;
      text-align: center
    }
  }

  @include breakpoint(large) {
    position: relative;

    &__map {
      width: 100%;
      height: rem(600);
    }

    &__text {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50%;
      width: 30%;
      text-align: left;
      background-color: $color-brand-honw;
      padding: $spacing-m $spacing-xl;
      font-size: $font-size-xl;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      .subhead {
        text-align: left;
      }
    }
  }
}

#map {
  canvas {
    background: white !important;
  }
}
