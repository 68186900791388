@import '~foundation-sites/scss/util/util';
@import 'common/mixins';

/* -----------------------------------------------------------------------------
/ COLOR PALETTE
/ -----------------------------------------------------------------------------

/ COLORS
/ http:/chir.ag/projects/name-that-color/ */

$_color-cod-gray: #111;
$_color-scorpion: #5c5c5c;
$_color-mercury: #e8e8e8;
$_color-wild-sand: #f5f5f5;
$_color-broom: #ffec12;
$_color-blue: #0031ff;
$_color-bittersweet: #ff7165;
$_color-mine-shaft: #3e3e3e;
$_color-carribean-green: #00c794;
$_color-aquamarine-blue: #7dcce1;
$_color-fuchsia: #8b67c0;
$_color-orange: #ff5400;

/* DEDICATED COLORS */
$color-black: #000;
$color-white: #fff;
$color-light-gray: $_color-wild-sand;
$color-brand-yellow: $_color-broom;
$color-dark-gray: $_color-cod-gray;
$color-mid-gray: $_color-mine-shaft;
$color-gray: $_color-scorpion;
$color-brand-blue: $_color-blue;
$color-brand-pink: $_color-bittersweet;
$color-brand-aqua: $_color-aquamarine-blue;
$color-brand-violet: $_color-fuchsia;
$color-accent: $color-brand-yellow;
$color-brand-honw: $_color-carribean-green;
$color-brand-tnx: $_color-orange;

$color-text: $color-dark-gray;
$color-text-faded: $color-gray;
$color-lines: $color-light-gray;
$color-border: $color-light-gray;
$color-border-dark: $color-mid-gray;

/* -----------------------------------------------------------------------------
/ SPACING
/ ----------------------------------------------------------------------------- */

$spacing-xxs: rem(5px);
$spacing-xs: rem(10px);
$spacing-s: rem(15px);
$spacing-m: rem(20px);
$spacing-l: rem(30px);
$spacing-xl: rem(40px);
$spacing-xxl: rem(50px);
$spacing-xxxl: rem(60px);

$spacing: (
  xxs: $spacing-xxs,
  xs: $spacing-xs,
  s: $spacing-s,
  m: $spacing-m,
  l: $spacing-l,
  xl: $spacing-xl,
  xxl: $spacing-xxl
);

/* -----------------------------------------------------------------------------
/ LAYOUT
/ ----------------------------------------------------------------------------- */

$max-page-width: rem(1600);
$breakpoint-sublarge: 1260px;
$content-width: rem(1300);
$content-wrapped-width: rem(1200);
$paragraph-wrapped: rem(640);

/* -----------------------------------------------------------------------------
/ TYPOGRAPHY
/ ----------------------------------------------------------------------------- */

$font-primary: 'PublicSansFont', 'Helvetica Neue', Arial, sans-serif;

$font-size-xs: rem(14);
$font-size-s: rem(15);
$font-size-m: 16px;
$font-size-l: rem(20);
$font-size-xl: rem(26);

$font-size-title-m: rem(30);
$font-size-title-l: rem(40px);
$font-size-title-xl: rem(50px);
$font-size-title-xxl: rem(60px);

$line-height-xsmall: 1;
$line-height-small: 1.3;
$line-height-normal: 1.6;
$line-height-large: 1.8;

$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-semibold: 700;
$font-weight-bold: 900;

$letter-spacing: rem(2);

/* -----------------------------------------------------------------------------
/ BRAND
/ ----------------------------------------------------------------------------- */

$logo: 'logo-blackboat.svg';

/* -----------------------------------------------------------------------------
/ Z-INDICES
/ ----------------------------------------------------------------------------- */
$zindex-level-1: 250;
$zindex-level-2: 500;
$zindex-level-3: 750;
$zindex-modal: 1000;

/* -----------------------------------------------------------------------------
/ COMPONENTS
/ ----------------------------------------------------------------------------- */

$control-height: rem(50);
$control-height-reduced: rem(40);
$info-btn-size: 54px;
$info-btn-size--small: 38px;

$logo-large-width: rem(90);

$border-radius-s: 2px;
$border-radius-m: 4px;

@mixin divider($margintop) {
  &::after {
    content: '';
    display: block;
    width: 100%;
    max-width: $content-wrapped-width;
    height: 1px;
    margin: $margintop auto 0;
    border: none;
    background-size: 6px 1px;
  }
}

/* -----------------------------------------------------------------------------
/ FOUNDATION OVERRIDES
/ ----------------------------------------------------------------------------- */
$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1240px,
  xxlarge: 1800px
);

$grid-column-gutter: (
  small: 20px,
  medium: 20px
);
$grid-margin-gutters: (
  small: 20px,
  medium: 20px
);

/* Tabs */
$tab-margin: 0;
$tab-background: transparent;
$tab-background-active: transparent;
$tab-item-font-size: rem-calc(16);
$tab-item-background-hover: transparent;
$tab-item-padding: 1.25rem 1.5rem;
$tab-expand-max: 6;
$tab-content-background: transparent;
$tab-content-border: transparent;
$tab-content-padding: 1rem;

/* Reveals */
$reveal-border: 1px solid transparent !default;
$reveal-radius: 0;
$reveal-overlay-background: rgba($color-dark-gray, 0.96) !default;

/* Boxshadow  */
$boxshadow-regular: 0 10px 30px 0 rgba(0, 0, 0, 0.15);

/* Tooltip */
.tooltip {
  border-radius: $border-radius-m;
  background-color: $color-dark-gray;
  text-align: center;
  min-width: rem(200);

  &.top::before {
    border: solid rem(8);
    border-color: $color-dark-gray transparent transparent !important;
  }

  &.bottom::before {
    border: solid rem(8);
    border-color: transparent transparent $color-dark-gray !important;
  }

  &.left::before {
    border: solid rem(8);
    border-color: transparent transparent transparent $color-dark-gray !important;
  }

  &.right::before {
    border: solid rem(8);
    border-color: transparent $color-dark-gray transparent transparent !important;
  }
}
