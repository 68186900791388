@import '../config';

@charset "UTF-8";

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

/* Settings
// ================================================== */
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #000 !default;
$hamburger-layer-border-radius: 0 !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

/* To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly. */
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

.hamburger {
  width: $hamburger-layer-width * 2;
  height: $hamburger-layer-width * 2;
  display: inline-block;
  cursor: pointer;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  /* Normalize (<button>) */
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  margin: 0;
  overflow: visible;

  &-box {
    width: $hamburger-layer-width * 2;
    height: $hamburger-layer-width * 2;
    display: inline-block;
    position: relative;
  }

  &-inner {
    display: block;

    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
      content: '';
      display: block;
      left: $hamburger-layer-width / 2;
    }

    &::before {
      top: 25px;
      transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
      width: $hamburger-layer-width / 2;
      top: 32px;
      transition: all 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &:hover {
    .hamburger {
      &-inner {
        &::after {
          width: $hamburger-layer-width;
        }
      }
    }
  }

  &.is-active {
    .hamburger {
      &-inner {
        transition-delay: 0.22s;
        background-color: transparent !important;

        &::before {
          transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: rotate(45deg);
        }

        &::after {
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: rotate(-45deg);
        }

        &::after,
        &::before {
          background-color: $hamburger-active-layer-color;
          width: $hamburger-layer-width;
          top: $hamburger-layer-width - ($hamburger-layer-height / 2);
        }
      }
    }
  }
}
