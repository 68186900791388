@import '../config';

/*! purgecss start ignore */

.quote {
  background: $color-brand-blue;
  color: $color-white;
  display: flex;
  flex-wrap: wrap;

  &__inner {
    order: 1;
    &__text {
      font-size: $font-size-l;
      display: block;
      max-width: 80%;
      margin: $spacing-xl auto $spacing-m;
      position: relative;
      text-align: left;
      &::before {
        content: '„';
        font-size: rem(100);
        font-style: italic;
        display: block;
        position: absolute;
        top: -130px;
      }
    }
    @include breakpoint(large) {
      order: 2;
      &__text {
        max-width: 70%;
        font-size: $font-size-xl;
        &::before {
          top: -150px;
        }
      }
    }
    &__author {
      text-transform: uppercase;
      font-size: $font-size-s;
      margin-top: $spacing-m;
    }
  }

  &__logo {
    background: $color-white;
    display: flex;
    padding: $spacing-m;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    order: 2;
    &__inner {
      max-width: rem(300);
      img {
        max-width: 100%;
      }
    }
    @include breakpoint(large) {
      order: 1;
      width: 30%;
      //margin: $spacing-l;
    }
  }
  @include breakpoint(large) {
    flex-wrap: nowrap;
  }
}

.page-honw .quote {
  background: $color-brand-honw;
}
.page-wnc .quote {
  background: $color-brand-violet;
}

.page-sfo .quote {
  background: $color-brand-pink;
}

/*! purgecss end ignore */
