@import '../config';

/*! purgecss start ignore */
.media {
  .flex {
    > div {
      &.items-2 {
        width: 50%;
      }
      &.items-3 {
        width: 33.33%;
      }
    }
  }
}

.imageText {
  position: relative;

  &__text {
    position: relative;

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &--large > p,
    &--large > ul,
    &--large > ol {
      font-size: $font-size-l;

      @include breakpoint(large) {
        font-size: $font-size-xl;
      }
    }

    &--xlarge > p,
    &--xlarge > ul,
    &--xlarge > ol {
      font-size: $font-size-xl;
      font-weight: $font-weight-medium;
      @include breakpoint(large) {
        font-size: $font-size-title-xxl;
        line-height: 1.3;
      }
    }

    &--small > p,
    &--small > ul,
    &--small > ol {
      font-size: $font-size-xs;
      @include breakpoint(large) {
        font-size: $font-size-s;
      }
    }
  }

  p {
    a {
      text-decoration: underline;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include breakpoint(medium only) {
    &__text {
      .case-detail & {
        padding: $spacing-xl * 2 0 0 0;
      }
    }
  }

  @include breakpoint(large) {
    &.one-col {
      max-width: 75%;
    }

    &.two-cols {
      column-count: 2;
      column-gap: 40px;
    }

    &.three-cols {
      column-count: 3;
      column-gap: 40px;
    }

    &__image {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  &.inverted {
    color: $color-white;
  }

  .effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-text;
    transform-origin: left center;
    transform: scaleX(0);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    pointer-events: none;
    color: $color-white;

    span {
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      letter-spacing: 1px;
      text-transform: uppercase;
      opacity: 0;
      transition: opacity 0.3s;
      transition-delay: 0.2s;
    }
  }

  &:hover {
    .effect {
      transform: scaleX(1);
      span {
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }
}

@include breakpoint(medium) {
  .wrapped.two-third > div {
    max-width: 75%;
  }
}

@keyframes imageHover {
  0% {
    transform: scaleX(0);
  }
  33% {
    transform: scaleX(1);
  }

  66% {
    transform: scaleX(1) translateX(0);
  }

  100% {
    transform: scaleX(1) translateX(100%);
  }
}

.page-sfo {
  .imageText {
    .effect {
      background-color: $color-brand-blue;
      color: $color-white;
    }
  }

  .layout {
    .effect svg path {
      fill: $color-white;
    }
  }
}

$anim-font-size: rem-calc(40);
$anim-font-size-large: rem-calc(100);

.page-tnx {
  .type-animation {
    font-size: $anim-font-size;
    font-weight: $font-weight-bold;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacing-l 0;
    h1 {
      font-weight: $font-weight-bold;
      font-size: $anim-font-size;
      text-transform: uppercase;
      max-width: 100%;
      z-index: 2;
      > span {
        display: inline;
        font-size: inherit;
        font-weight: 100;
        color: $color-brand-tnx;
        padding-left: $spacing-s;
      }
    }
    &__x {
      width: rem-calc(110);
      height: rem-calc(110);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 296.37 296.37'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='105.76' x2='211.07' y1='75.14' y2='256.45' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.22' stop-color='%237dcce1'/%3E%3Cstop offset='.28' stop-color='%2394bfbb'/%3E%3Cstop offset='.43' stop-color='%23cf9e5c'/%3E%3Cstop offset='.54' stop-color='%23ff8512'/%3E%3Cstop offset='.68' stop-color='%23ff5400'/%3E%3Cstop offset='.92' stop-color='%23ff5400'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath d='M98.79 32.93H0V0h98.79v32.93ZM296.37 0h-98.79v32.93h98.79V0ZM131.72 65.86H32.93v32.93h98.79V65.86Zm131.72 0h-98.79v32.93h98.79V65.86Zm-65.86 65.86H98.79v32.93h98.79v-32.93Zm-65.86 65.86H32.93v32.93h98.79v-32.93Zm131.72 0h-98.79v32.93h98.79v-32.93ZM98.79 263.44H0v32.93h98.79v-32.93Zm197.58 0h-98.79v32.93h98.79v-32.93Z' style='fill:url(%23a)'/%3E%3C/svg%3E");
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: cover;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-55%);
      z-index: 1;
    }
    &__container {
      margin-top: rem-calc(-20);
      font-weight: $font-weight-medium;
      z-index: 2;

      .text {
        display: inline;
        font-style: italic;
      }
    }

    @include breakpoint(large) {
      font-size: $anim-font-size-large;
      h1 {
        font-size: $anim-font-size-large;
        justify-content: start;
      }
      &__x {
        width: rem-calc(250);
        height: rem-calc(250);
      }
      &__container {
        margin-top: rem-calc(-40);
      }
    }
  }
}

/*! purgecss end ignore */
