@import '../config';
@import '../common/mixins';

/*! purgecss start ignore */
.page-404 {
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  background-image: url('../../img/404.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: $color-white;

  .contact,
  footer {
    display: none !important;
  }

  @include breakpoint(large) {
    section {
      max-width: 80%;
    }
  }
}

/*! purgecss end ignore */
