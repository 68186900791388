@import '../config';

/*! purgecss start ignore */
.counter {
  display: flex;
  flex-wrap: wrap;
  &__item {
    text-align: left;
    width: 50%;
    margin-bottom: $spacing-m;
    .value {
      font-size: $font-size-title-xl;
      line-height: 1.1;
    }
    label {
      text-transform: uppercase;
      font-size: $font-size-s;
      padding-right: $spacing-s;
      hyphens: auto;
    }
  }

  @include breakpoint(large) {
    justify-content: space-between;
    &__item {
      width: 33.33%;
      .value {
        font-size: $font-size-title-xxl;
        line-height: 1.1;
      }
    }
    &--four {
      .counter__item {
        width: 25%;
      }
    }
  }
}

.page-sfo {
  .counter label {
    color: $color-white
  }
}

.page-tnx {
  .counter {
    &__item {
      width: 100%;
      text-align: center;

      .value {
        text-align: center;
      }
    }
    @include breakpoint(medium) {
      &__item {
        width: 33.33%;
        text-align: left;

        .value {
          text-align: left;
        }
      }
    }
    .value {
      @include breakpoint(large) {
        font-size: $font-size-title-xxl * 2;
      }
    }
  }
}


/*! purgecss end ignore */
