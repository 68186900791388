/**
 * Grid mixin
 */

@mixin col($col, $sum, $gap: 1em, $align: top) {
  width: 100%;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  padding-left: $gap;
  padding-right: $gap;

  @include breakpoint(medium) {
    width: percentage($col/$sum);
    vertical-align: $align;
  }
}

/**
 * Removing the tap highlight color on touch devices
 * http://phonegap-tips.com/articles/essential-phonegap-css-webkit-tap-highlight-color.html
 *
 * [1] Removing the tap highlight color on iOS
 * [2] … and some Androids
 */
@mixin remove-tap-highlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/**
 * The micro clearfix
 *
 * @see http://nicolasgallagher.com/micro-clearfix-hack/
 */
@mixin clearfix {
  *zoom: 1;

  &::after,
  &::before {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin grid-flex-wrapper($spacing) {
  display: flex;
  margin-left: $spacing * -1px;
  margin-right: $spacing * -1px;

  > div,
  > button,
  > select {
    margin-left: $spacing * 1px;
    margin-right: $spacing * 1px;
    flex-grow: 1;
  }
}

@mixin hide-control {
  appearance: none;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border: 0;
  outline: 0;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

/**
 * Polyfill CSS `object-fit` on IE11, Edge
 *
 * @see https://github.com/bfred-it/object-fit-images
 * @see https://developer.mozilla.org/de/docs/Web/CSS/object-fit
 *
 * @param {string} $_object-fit - fill, contain, cover, none, scale-down
 */
@mixin object-fit($_object-fit: cover) {
  object-fit: $_object-fit;
  font-family: 'object-fit: #{$_object-fit};';
}

@function svg-data-uri($_svg-string) {
  /* $_svg-data: encode-svg($_svg-string); */
  @return 'data:image/svg+xml,#{$_svg-data}';
}

/**
 * Hide visually
 */
@mixin hide {
  @include sr-only();

  visibility: hidden;
}

/**
 * Only display content to screen readers
 *
 * @see http://a11yproject.com/posts/how-to-hide-content
 * @see http://hugogiraudel.com/2016/10/13/css-hide-and-seek/
 */
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

/**
 * Use in conjunction with .sr-only to only display content when it's focused.
 *
 * Useful for "Skip to main content" links
 * @see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 *
 * Credit: HTML5 Boilerplate
 */
@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    clip-path: none;
  }
}

/** use to toggle visibility **/

@mixin toggle-element {
  height: 0;
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  transition: height 0.2s, opacity 0.2s;

  &.is-active {
    overflow: auto;
    height: auto;
    max-height: none;
    opacity: 1;
  }
}

/**
 * Replace `$search` with `$replace` in `$string`
 * @author Hugo Giraudel
 * @see https://css-tricks.com/snippets/sass/str-replace-function/
 *
 * @param {string} $string - Initial string
 * @param {string} $search - Substring to replace
 * @param {string} $replace ('') - New value
 * @return {string} - Updated string
 */
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}

/**
 * Remove the unit of a given number
 *
 * @param {number} $_number to remove unit from
 * @return {number} an unitless number
 */
@function strip-unit($_number) {
  @if type-of($_number) == 'number' and not unitless($_number) {
    @return $_number / ($_number * 0 + 1);
  }
  @return $_number;
}

/**
 * Encode SVG to use them as data-uri for background-image
 * Encodes <, > and #.
 *
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 *
 * @param {string} $string - SVG code to encode
 * @returns {string} - encoded String
 */
@function encode-svg($string) {
  $string: str-replace($string, '<', '%3C');
  $string: str-replace($string, '>', '%3E');
  $string: str-replace($string, '#', '%23');

  @return $string;
}

/**
 * Convert Pixel to EM
 */

$browser-context: 18;

@function rem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

@mixin fade-in($duration: 0.3s, $delay: 0.3s, $order: 1, $easing: ease-out) {
  opacity: 0;
  transform: translateY(-50px);
  $element-delay: $delay * $order;

  transition: opacity $duration $easing $element-delay,
    transform $duration $easing $element-delay;

  .is-active & {
    opacity: 1;
    transform: translateY(0);
  }
}

.inview {
  @include fade-in();

  .no-csstransitions &,
  .no-cssanimations & {
    opacity: 1;
    transform: translateY(0);
  }
}

/**
 * Check if hover supported
 */

@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}
