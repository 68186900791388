@import '../config';
@import '../common/button';

/*! purgecss start ignore */
.newsletter {
  input[type='email'] {
    display: inline-block;
    margin-left: $spacing-xs;
    margin-right: $spacing-m;
    border: none;
    border-bottom: 2px solid $color-lines;
    box-shadow: none;
    font-size: inherit;
    width: auto;
  }

  @include breakpoint(large) {
    text-align: center;
    form,
    .form {
      max-width: 75%;
      margin: auto;
      text-align: left;
      form {
        max-width: 100%;
      }
    }
  }

  .inline-form {
    [data-error-message] {
      font-size: $font-size-m;
      color: $color-brand-pink;
      font-weight: $font-weight-normal;
    }
    .fui-alert {
      padding-left: 0;
      font-size: $font-size-m;
    }
    .grecaptcha-badge {
      right: 0 !important;
    }
  }

  .inline-field {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-s;
    align-items: baseline;
    > div {
      order: 1;
    }
    label {
      @include h3;
      > span {
        display: none;
      }
    }
    input {
      order: 2;
      color: $color-text;
    }
  }
}

.page-mag {
  .newsletter {
    .subhead {
      color: $color-white;
    }
    input[type='email'] {
      background: $color-text;
      border-bottom-color: $color-text-faded;
      color: $color-white;
    }
  }
}

.newsletterPage {
  .newsletter {
    background: $color-light-gray;

    input[type='email'] {
      background: $color-light-gray;
      border-bottom-color: #ccc;
    }
  }
}

.page-sfo {
  .newsletter {
    background: $color-text;
    .subhead {
      color: $color-white;
    }
    input[type='email'] {
      background: $color-text;
      border-bottom-color: $color-white;
      color: $color-white;
    }
    label,
    button {
      color: $color-white;
    }
  }
}

// Archive

.display_archive {
  @include breakpoint(medium) {
    font-size: $font-size-l;
  }

  .campaign {
    color: $color-text;
    padding: $spacing-xs 0 $spacing-xs $spacing-l;

    transition: transform 0.3s;

    &:before {
      display: block;
      content: '';
      border-top: 2px solid $color-text;
      width: rem(20);
      position: relative;
      left: $spacing-l * -1;
      top: rem(17);
      @include breakpoint(medium) {
        top: rem(23);
      }
    }
    a {
      color: $color-text;
    }
    &:hover {
      transform: translateX(15px);
    }
  }
}

/*! purgecss end ignore */
