@import '../config';

/*! purgecss start ignore */
.timeline {
  &__item {
    position: relative;
    h2, h3, h4, p, .step {
      z-index: 2;
    }

    .step {
      font-weight: $font-weight-medium;
    }
  }
  &__floatingImages {
    display: flex;
    position: absolute;
    top:  $spacing-xl*-1;
    left:  $spacing-xl;
    right: 0;
    z-index: -1;

    .item {
      width: rem(100);
      margin-right: $spacing-xs;
      border-radius: 100%;

      img {
        position: relative;
        max-width: 100%;
      }
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 1px;
    height: 100%;
    background: rgba(255,255,255,.5);
  }

  &__item {
    &::before {
      content: '';
      position: absolute;
      left: rem(-7);
      top: rem(6);
      width: rem(15);
      height: rem(15);
      border: 2px solid $color-text;
      border-radius: 100%;
      background: $color-brand-pink;
    }
    &.floating {
      margin-top: $spacing-xl * 2;
    }
  }

  @include breakpoint(small only) {
    &__item {
      padding-left: $spacing-l;
      padding-bottom: $spacing-xl;

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    &__floatingImages {
      max-width: 80%;
      left: auto;
    }
  }

  @include breakpoint(medium) {
    &::before {
      left: 50%;
    }

    &__item {
      width: calc(50% - #{$spacing-xl});
      text-align: right;
      transform: translateY(-7px);

      &.floating {
        margin-top: 0;
      }

      .copy p, .step {
        text-align: right;
      }

      &::before {
        left: auto;
        right: $spacing-xl * -1;
        transform: translateX(8px);
      }

      &:first-of-type {
        transform: none;
        margin-bottom: 0;
      }

      &:nth-child(even) {
        text-align: left;
        margin-left: calc(50% + #{$spacing-xl});

        .copy p, .step {
          text-align: left;
        }

        &::before {
          left: $spacing-xl * -1;
          right: auto;
          transform: translateX(-7px);
        }
      }
    }
  }

  &.timeline-service {
    display: none;
    &.active {
      display: block;
      margin-top: $spacing-xl;
      margin-bottom: $spacing-xl * 3;
    }
  }
}

.timelineSelector {
  position: relative;
  margin: $spacing-m auto;
  display: block;
  z-index: 20;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: $font-size-l;
    margin-bottom: $spacing-xl;
  }

  &__wrapper {
    padding-right: rem(25);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
    background-size: rem(24) rem(24);
    background-position: right rem(4);
    background-repeat: no-repeat;

    height: rem(42);
    overflow: hidden;
    z-index: 2;
    display: inline-flex;
    flex-direction: column;

    &.active {
      overflow: inherit;
      z-index: 20;
    }

    &:hover {
      cursor: pointer;
    }

    @include breakpoint(medium) {
      display: flex;
      margin-left: $spacing-m;
      margin-right: $spacing-m;
      min-width: rem(200);
    }
  }

  &__item {
    display: none;
    order: 2;
    padding-bottom: $spacing-xs;
    background: rgba($color-text, 0.9);
    .text {
      border-bottom: 2px solid transparent;
      transition: border-bottom-color .3s;
    }

    &.active {
      display: block;
      order: 1;
      .text {
        display: inline-block;
        border-bottom-color: $color-brand-blue;
      }
      .timelineSelector__wrapper:hover & {
        .text {
          border-bottom-color: $color-white;
        }
      }
    }

    .timelineSelector__wrapper.active & {
      display: block;

      .item.active {
        order: 1;
      }
    }
  }
}

.timelineItems {
  z-index: 10;
  @include breakpoint(medium) {
    margin-top: $spacing-xl;
  }
}

.page-blb {
 .timeline {
  &::before {
    background: rgba(0,0,0,0.3);
  }
  &__item {
    &::before {
      background: $color-brand-blue;
      border-color: $color-white;
    }
  }
  &Selector {
    &__wrapper {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(0,0,0,1)'/%3E%3C/svg%3E");
    }
    &__item {
      background: rgba($color-white, 0.9);
      &.active {
        .timelineSelector__wrapper:hover & {
          .text {
            border-bottom-color: $color-text;
          }
        }
      }
    }
  }
 } 
}

/*! purgecss end ignore */
