img {
  position: relative;

  a {
    display: block;
    border: none;
  }

  &.blur-up {
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
  }

  &.blur-up.lazyloaded {
    filter: blur(0);
  }
}

/*! purgecss start ignore */
.image {
  display: block;

  &--podcast-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-text;
    height: 100%;
    img {
      height: 100%;
      width: auto;
    }
  }
}

/*! purgecss end ignore */
