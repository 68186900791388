@import '../config';

.footer {
  background-color: $color-white;
  padding-bottom: $spacing-l;

  &__wrapper {
    > div {
      margin-bottom: $spacing-l;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 $spacing-s 0;
      li {
        padding: rem(5) 0;
        font-size: $font-size-s;
        font-weight: $font-weight-medium;

        > ul {
          padding: 0;
          margin: 0;
          li {
            font-weight: $font-weight-normal;
          }
        }
      }
    }
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      > div {
        flex: 1 0 100%;
      }
      > ul {
        flex: 1 0 33.33%;
        text-align: left;
        .bot & {          
          flex: 0 1 auto;
          padding-right: $spacing-s;
        }
      }
    }

    @include breakpoint(large) {
      > div,
      ul {
        padding: 0 $spacing-s;
        flex: 1 0 auto;
        
        .bot & {          
          flex: 0 1 auto;
        }

        > ul {
          padding: 0;
        }
      }
    }
  }

  &__legals {
    font-weight: $font-weight-normal;
    font-size: $font-size-xs;
    margin: 0;
    li {
      display: inline-block;
      margin-right: $spacing-m;
      a {
        color: $color-text-faded;
      }
    }
  }

  main.jobEntry & {
    background-color: $color-light-gray;
  }
}

.page-mag,
.page-sfo,
.bot {
  .footer {
    background: $color-text;
    &__legals {
      li {
        a {
          color: $color-white;
        }
      }
    }
  }
}

.page-wnc {
  .footer {
    background: $color-light-gray;
  }
}

.page-tnx {
  .footer {
    background: transparent;
  }
}
