@import '../config';

/*! purgecss start ignore */
.list {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;

  &--brands {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    li {
      width: 50%;
      min-height: rem(100);
      display: flex;
      align-items: center;
      justify-content: center;

      svg,
      picture,
      img {
        max-width: rem(90);
        margin: $spacing-m auto;
      }
    }

    @include breakpoint(medium) {
      li {
        width: 20%;
        .home & {
          width: 25%;
        }

        svg,
        picture,
        img {
          max-width: rem(180);
          max-height: rem(80);
          margin: $spacing-m auto;
        }
      }
    }
  }

  &--mega {
    font-size: $font-size-title-l;
    font-weight: $font-weight-medium;
    line-height: $line-height-xsmall;

    li {
      position: relative;
      display: block;
      padding: $spacing-xs 0;
      flex: 1;

      a,
      span {
        transition: transform 0.5s;
        display: block;
      }

      &:hover {
        a,
        span {
          transform: translateX(15px);
          color: inherit;
        }
      }
    }

    @include breakpoint(medium) {
      font-size: $font-size-title-l;
    }

    @include breakpoint(large) {
      font-size: $font-size-title-xxl;
      line-height: $line-height-small;
      li {
        padding: $spacing-s 0;
      }
    }
  }

  &--tall {
    li {
      font-size: $font-size-title-m;
      font-weight: $font-weight-medium;
      line-height: $line-height-small;
      padding: $spacing-xxs 0;

      @include breakpoint(medium) {
        font-size: $font-size-title-l;
      }
    }
  }

  &--jobs {
    list-style: none;
    margin: $spacing-m 0;
    border-bottom: 1px solid $color-border;

    li {
      border-top: 1px solid $color-border;
      padding: $spacing-s 0;

      .btnArrow__text {
        top: rem-calc(2);
      }
    }
  }

  &--events {
    list-style: none;
    margin: $spacing-m 0;
    border-bottom: 1px solid $color-border;

    li {
      border-top: 1px solid $color-border;
      font-size: $font-size-title-m;
      font-weight: $font-weight-medium;
      line-height: $line-height-small;
      padding: $spacing-m 0;

      @include breakpoint(large) {
        font-size: $font-size-title-l;
        padding: $spacing-l 0;
      }

      a {
        background-image: linear-gradient(
          to right,
          $color-accent 0,
          $color-accent 100%
        );
        background-position: 0 -0.1em;
        background-size: 0 100%;
        background-repeat: no-repeat;
        transition: background 0.5s;

        span {
          color: $color-text-faded;
          font-weight: $font-weight-normal;
        }
      }

      &:hover {
        a {
          transition: background 0.5s;
          background-size: 100% 100%;
          color: $color-text;
        }
      }
    }

    &--past {
      li {
        font-size: $font-size-title-m;

        @include breakpoint(large) {
          font-size: $font-size-title-m;
          padding: $spacing-m 0;
        }
      }
    }
  }

  &--regular {
    list-style-type: disc;
    margin-left: $spacing-m;
    line-height: $line-height-large;
    font-size: $font-size-m;
    font-weight: $font-weight-medium;

    li {
      margin: $spacing-xs 0;
    }

    @include breakpoint(medium) {
      font-size: $font-size-l;
    }

    @include breakpoint(large) {
      max-width: 50%;
    }
  }
}

/*! purgecss end ignore */
