/**
 * components/pages.css
 *
 * Pages styles
 *
 */
@import '../config';
@import 'mixins';

/*! purgecss start ignore */
.jobs__item {
  font-size: $font-size-m;
  margin-bottom: $spacing-m;

  @include breakpoint(medium) {
    font-size: $font-size-l;
    margin-bottom: $spacing-xl;
  }

  a {
    text-decoration: underline;
  }
}

.casesList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin-bottom: $spacing-l;
    .btnArrow {
      margin-top: $spacing-xxs;
      max-width: 90%;
    }
    @include breakpoint(medium) {
      width: 50%;
      padding: 0 0 $spacing-xl * 2 0;
      margin-bottom: $spacing-s;
      .btnArrow {
        margin-top: 0;
      }

      .imageText {
        max-width: 95%;
        margin-bottom: $spacing-s;
      }
    }
  }
}

/*! purgecss end ignore */
