@import '../config';
@import '../common/mixins';
/*! purgecss start ignore */

.navFlyout {
  max-height: 100%;
  display: block;
  overflow: hidden;
  z-index: $zindex-level-1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform 0.3s linear 0.1s, background-color 0.3s linear 0.6s;
  transform: translateY(-100%);
  color: $color-text;
  height: 100%;

  .wrapped {
    height: 100%;
  }

  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  &__contact {
    margin-top: $spacing-xxl;

    &__divider {
      margin: $spacing-xxl 0 $spacing-m;
    }
  }

  &::after {
    position: absolute;
    content: '';
    bottom: -3px;
    height: 3px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }

  h1 {
    display: none;
  }

  &__menu {
    @include fade-in($order: 1);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      line-height: 2.2;

      li {
        font-size: $font-size-title-m;
        font-weight: $font-weight-medium;

        &.is-active > a {
          background-color: $color-brand-yellow;
        }
      }
    }

    span {
      display: block;
      color: $color-text-faded;
      font-size: $font-size-s;
    }
  }

  &.is-active {
    background: $color-light-gray;
    transform: translateY(0);
    transition: transform 0.3s linear 0s, background-color 0.1s linear 0s;
  }

  @include breakpoint(medium) {
    &__menu {
      ul {
        li {
          font-size: $font-size-title-l;
        }
      }
    }
  }
}

.page-sfo .navFlyout {
  &__menu ul li {
    &.is-active > a {
      background-color: $color-brand-blue;
      color: $color-white;
    }
  }
}
/*! purgecss end ignore */
