@import '../config';

$_breakpoint-carousel: 1330px;
$_max-width: rem-calc(1400);

/*! purgecss start ignore */
.carousel {
  $self: &;

  position: relative;
  -webkit-overflow-scrolling: touch;

  .swiper-container {
    width: 100%;
    max-width: 100%;
    transform: translate3d(0, 0, 0);
  }

  .swiper-wrapper {
    .ie9 & {
      overflow: hidden;
    }
  }

  .swiper-pagination {
    position: relative;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
    text-align: center;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    width: rem(10);
    height: rem(10);
    margin: 0 3px !important;
    background: $color-brand-pink;
    border-color: $color-brand-pink;
    border-radius: 100%;
    opacity: 0.4;
    transition: all 0.3s;

    #{ $self }--one-item &,
    #{ $self }--two-items &,
    #{ $self }--three-items & {
      background: $color-brand-pink;
      opacity: 0.4;
    }

    &:hover {
      background: $color-text;
      opacity: 0.6;
      cursor: pointer;

      #{ $self }--one-item &,
      #{ $self }--two-items &,
      #{ $self }--three-items & {
        background: $color-dark-gray;
      }
    }

    &:focus {
      outline: none;
    }

    &.swiper-pagination-bullet-active {
      background: $color-light-gray;
      opacity: 1;
      border: none;

      #{ $self }--one-item &,
      #{ $self }--two-items &,
      #{ $self }--three-items & {
        background: $color-light-gray;
        opacity: 1;
      }

      &:hover {
        #{ $self }--one-item &,
        #{ $self }--two-items &,
        #{ $self }--three-items & {
          background: $color-dark-gray;
        }
      }
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    left: $spacing-m;
  }

  .swiper-button-next {
    right: $spacing-m;
  }

  .card {
    background-color: $color-white;
    border: none;
    //max-width: rem(350);
    height: 100%;

    .element-scaler {
      background-color: $color-light-gray;
    }

    &--alt {
      background-color: $color-border;
    }

    .latestYoutube__img {
      top: -16.66%;
    }

    &__content {
      padding: $spacing-m;
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      p {
        span.light {
          color: $color-text-faded;
          font-size: $font-size-s;
          text-transform: uppercase;
          display: block;
          margin-bottom: $spacing-s;
        }
      }

      a {
        display: block;
        margin-top: $spacing-s;
        text-decoration: underline;
        font-weight: $font-weight-medium;
        align-self: flex-end;
        font-size: $font-size-m;
        flex: 1 0 100%;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: rem(30);
    height: rem(30);
    top: 50%;
    margin-top: rem(-15);
    opacity: 1;
    z-index: $zindex-level-1;
    transition: opacity 0.3s;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg id='Gruppe_27374' data-name='Gruppe 27374' xmlns='http://www.w3.org/2000/svg' width='37' height='37' viewBox='0 0 37 37'%3E%3Cpath id='Pfad_7771' data-name='Pfad 7771' d='M0,0H37V37H0Z' fill='none'/%3E%3Cpath id='Pfad_7772' data-name='Pfad 7772' d='M14.5,6.4l2.18-2.18L28.667,16.213,16.676,28.2l-2.18-2.18,9.87-9.811Z' transform='translate(-2.833 2.287)'/%3E%3C/svg%3E%0A");
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.4;
  }

  &--horizontal,
  &--team {
    .swiper-slide {
      width: auto;
      height: auto;
    }
  }

  &--flipcards {
    .swiper-slide {
      width: rem(420);
      height: rem(500);
    }
  }

  @include breakpoint(medium) {
    &--horizontal {
      margin-bottom: $spacing-xl;
      .swiper-button-prev,
      .swiper-button-next {
        top: rem(-40);
        right: rem(60);
        &:not(.swiper-button-lock) {
          display: block;
        }
      }
      .swiper-button-prev {
        right: rem(90);
        left: auto;
      }

      .card {
        //max-width: rem(550);
        &__content a {
          font-size: $font-size-l;
        }
      }
    }
  }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    opacity: 1;
    cursor: pointer;
  }

  &--team {
    .card {
      //max-width: rem(450);
    }
  }

  @include breakpoint(small only) {
    .swiper-container {
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .swiper-slide {
      margin: 0 $spacing-xxs;
      height: auto;
      width: 80%;
      max-width: 80%;
      display: flex;

      .card {
        font-size: $font-size-s;
        span.light {
          font-size: $font-size-xs;
        }
      }
    }
  }
}

.noCarousel {
  max-width: $_max-width;
  width: 100%;
  margin: auto;
}

/*! purgecss end ignore */
