/**
 * components/typography.css
 *
 * Typography rules.
 *
 */
@import '../config';

@mixin h1 {
  font-family: $font-primary;
  font-size: $font-size-title-l;
  line-height: $line-height-small;
  margin-bottom: $spacing-xs;

  @include breakpoint(medium) {
    margin-bottom: $spacing-s;
  }

  @include breakpoint(large) {
    font-size: $font-size-title-xxl;
    max-width: 75%;
  }
}

@mixin h2 {
  font-family: $font-primary;
  font-size: $font-size-title-xl;
  line-height: $line-height-small;

  @include breakpoint(small only) {
    font-size: $font-size-title-m;
  }
}

@mixin h3 {
  font-family: $font-primary;
  font-size: $font-size-title-l;
  font-weight: $font-weight-medium;
  line-height: $line-height-small;

  & + ul {
    margin-bottom: $spacing-xxl;
  }

  @include breakpoint(small only) {
    font-size: $font-size-l;
  }
}

@mixin h4 {
  font-family: $font-primary;
  font-size: $font-size-title-m;
  line-height: 1.3;
  @include breakpoint(small only) {
    font-size: $font-size-l;
  }
}

@mixin h5 {
  font-family: $font-primary;
  font-size: $font-size-l;
  line-height: 1.3;
  @include breakpoint(small only) {
    font-size: $font-size-m;
  }
}

/*! purgecss start ignore */
p {
  line-height: $line-height-large;
  font-size: $font-size-m;

  @include breakpoint(medium) {
    font-size: $font-size-l;
  }

  &.tall,
  .tall & {
    font-size: $font-size-l;
    line-height: $line-height-normal;

    @include breakpoint(medium) {
      font-size: $font-size-xl;
    }
  }

  &.small {
    font-size: $font-size-m;
  }

  &.light {
    color: $color-text-faded;

    .main--inverted & {
      color: $color-white;
      opacity: 0.6;
    }
  }

  &.wrapped {
    max-width: $paragraph-wrapped;
    margin-left: auto;
    margin-right: auto;
  }

  > small,
  + small {
    font-size: $font-size-xs;
    color: $color-text-faded;
    display: block;
    margin-bottom: $spacing-xs;
    padding-top: $spacing-s;
  }

  &.limit-width {
    @include breakpoint(medium) {
      max-width: 75%;
    }
  }

  &.split {
    columns: 2 100px;
    column-gap: 20px;
  }
}

ul,
ol {
  line-height: $line-height-large;
  font-size: $font-size-m;

  @include breakpoint(medium) {
    font-size: $font-size-l;
  }
}

strong {
  font-weight: $font-weight-medium;

  &.highlight {
    background: $color-accent;
  }
}
.semibold {
  font-weight: $font-weight-semibold;
}

a {
  color: inherit;
  transition: color 0.3s ease-out;

  &.accent {
    color: $color-accent;
  }

  &.underline {
    text-decoration: underline;
  }
}

h1,
.h1 {
  @include h1();
}

h2,
.h2 {
  @include h2();
}

h3,
.h3 {
  @include h3();
}

h4,
.h4 {
  @include h4();
}

h5,
.h5 {
  @include h5();
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  display: block;
  font-weight: $font-weight-medium;
  //hyphens: auto;

  // span {
  //   color: $color-text-faded;
  //   display: block;
  //   font-size: $font-size-xs;
  // }
  // TODO CHECK Where do we need this?

  &.text-left {
    text-align: left;
  }

  > a {
    color: $color-text;
  }

  &.inverted {
    color: $color-white;
  }

  &.wide {
    max-width: 100%;
    hyphens: none;
  }

  > em,
  > strong {
    font-weight: inherit;
    font-style: inherit;
    @include breakpoint(medium) {
      white-space: nowrap;
    }
  }

  &.highlight {
    > em {
      color: $color-brand-tnx;
      white-space: inherit;
    }
  }
}

a:hover {
  color: $color-text-faded;
}

a:focus {
  color: $color-text;
}

blockquote {
  font-style: italic;
  font-size: $font-size-title-m;
  font-weight: $font-weight-medium;
  line-height: $line-height-small;
  color: $color-text;
  border-left: 0;
  padding: 0;
  margin: $spacing-m 0;

  @include breakpoint(medium) {
    font-size: $font-size-title-xl;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .main--inverted & {
    color: $color-white;
  }
}

@mixin category {
  font-family: $font-primary;
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  color: $color-text-faded;
  text-transform: uppercase;
}

.subhead,
.blbSubhead {
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  padding: $spacing-xs 0;
  color: $color-text-faded;

  .inside & {
    padding: $spacing-xs 0;
  }

  &.light {
    color: $color-text-faded;
  }

  @include breakpoint(medium) {
    padding: $spacing-xs 0;
  }

  + h1,
  + h2,
  + h3 {
    + p {
      //margin-top: $spacing-xl;
    }
  }
}

.text {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-column {
    @include breakpoint(medium) {
      column-count: 2;
    }
  }

  &-wrapped {
    @include breakpoint(medium) {
      max-width: 75%;
      display: block;
    }
  }
}

.link-underline {
  position: relative;
  cursor: pointer;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    right: auto;
    left: 0;
    bottom: 0;
    background: $color-text;
    transition: width 0.3s;
  }

  &:hover {
    color: inherit;

    &::after {
      width: 0;
      left: auto;
      right: 0;
    }
  }

  .main--inverted section & {
    &::after {
      background: $color-white;
    }
  }
}

.link-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

/*! purgecss start ignore */
@include breakpoint(medium) {
  .col {
    &-2 {
      column-count: 2;
      column-gap: $spacing-l;
    }

    &-3 {
      column-count: 3;
      column-gap: $spacing-l;
    }

    &-1,
    &-2,
    &-3 {
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.caption {
  font-size: $font-size-xs;
  text-align: right;
  color: $color-text;
  padding: $spacing-s 0 0 0;

  .main--inverted & {
    color: $color-white;
  }

  .full & {
    margin-right: $spacing-l;
  }
}

.carousel + .caption {
  display: none;
}

@include breakpoint(large) {
  .carousel {
    + .caption {
      display: block;
    }

    &--one-item {
      + .caption {
        position: relative;
        top: rem(-32);
      }
    }
  }
}

@include breakpoint(small only) {
  .wrapped.outside .caption {
    padding-left: $spacing-s;
    padding-right: $spacing-s;
  }
}

/*! purgecss end ignore */
