@import '../config';
@import '../common/mixins';

/* Arrow styles based on https://codepen.io/andgatjens/pen/PmLqaN */

$arrowSize: 10px;
$arrowBorderSize: 2px;
$arrowBarWidth: 2px;
$arrowBarHeight: 30px;
$arrowGrowing: 14px;
$arrowBarHeightHover: $arrowBarHeight + $arrowGrowing;
$arrowGrowingShift: $arrowGrowing / 2;
$transitionDuration: 0.2s;
$transitionEasing: ease-out;

@mixin rotate($angle) {
  transform: rotate($angle);
}

@mixin arrow(
  $arrowSize,
  $arrowBorderSize,
  $arrowBarWidth,
  $arrowBarHeight,
  $arrowGrowing
) {
  display: inline-block;
  height: $arrowBarHeight;
  width: $arrowBarHeight;
  position: relative;

  &::after {
    border-bottom-style: solid;
    border-bottom-width: $arrowBorderSize;
    border-right-style: solid;
    border-right-width: $arrowBorderSize;
    content: '';
    display: inline-block;
    height: $arrowSize;
    width: $arrowSize;
    left: calc(50% - #{$arrowSize/2});
    position: absolute;
    bottom: 0;
    transition: bottom $transitionDuration $transitionEasing;
    @include rotate(45deg);
  }

  &::before {
    bottom: 0;
    content: '';
    height: $arrowBarHeight;
    width: $arrowBarWidth;
    position: absolute;
    left: calc(50% - #{$arrowBorderSize/2});
    transform-origin: center center;
    transition:
      transform $transitionDuration $transitionEasing,
      height $transitionDuration $transitionEasing;
  }

  /* States */
  &--top {
    @include rotate(180deg);
  }

  &--right {
    @include rotate(270deg);
  }

  &--left {
    @include rotate(90deg);
  }
}

@mixin arrowColor($color) {
  &,
  .btnArrow__wrapper {
    &::after {
      border-color: $color;
    }

    &::before {
      background-color: $color;
    }
  }
}

/*! purgecss start ignore */
.btnArrow {
  display: block;
  line-height: $line-height-normal;
  &-container {
    display: block;
  }
  &__wrapper {
    display: inline-block;
    width: $arrowBarHeight;
    height: $arrowBarHeight;
    margin: $spacing-l;
    @include arrow(
      $arrowSize,
      $arrowBorderSize,
      $arrowBarWidth,
      $arrowBarHeight,
      $arrowGrowing
    );
    @include arrowColor($color-text);
  }

  &--with-text {
    display: flex;
    align-items: flex-start;
    font-weight: $font-weight-medium;

    .btnArrow {
      &__wrapper {
        margin: rem-calc(2) $spacing-m 0 0;
        flex-shrink: 0;
        @include breakpoint(small only) {
          margin-top: rem-calc(2);
        }
      }

      &__text {
        position: relative;
        top: rem-calc(5);
        transition: margin $transitionDuration $transitionEasing;
        line-height: $line-height-normal;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: $color-text;
          bottom: -2px;
          left: 0;
          right: auto;
          transition: opacity 0.3s;
          display: none;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;

    .btnArrow {
      &__wrapper {
        &::before {
          height: $arrowBarHeightHover;
          transform: translateY($arrowGrowingShift);
        }

        &::after {
          bottom: $arrowGrowingShift * -1;
        }
      }

      &__text {
        margin-left: $arrowGrowingShift;
      }
    }
  }
}

.btnArrowSimple {
  display: block;
  line-height: $line-height-normal;
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  display: flex;
  padding: $spacing-m 0;
  overflow: hidden;

  /* Store the SVG in a variable so it's easy to re-use */
  --svg: url('data:image/svg+xml,\
   <svg xmlns="http://www.w3.org/2000/svg" width="46" height="13" fill="none"><path fill="currentColor" d="M1.045 7.583h41.386l-3.443 3.568a1.111 1.111 0 0 0 0 1.532 1.024 1.024 0 0 0 1.479 0l5.227-5.417a1.111 1.111 0 0 0 0-1.532L40.467.317a1.02 1.02 0 0 0-1.479 0 1.111 1.111 0 0 0 0 1.532l3.443 3.568H1.045C.468 5.417 0 5.902 0 6.5s.468 1.083 1.045 1.083Z"/></svg>');

  &::before {
    content: '';
    display: block;
    //background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="46" height="13" fill="none"><path fill="currentColor" d="M1.045 7.583h41.386l-3.443 3.568a1.111 1.111 0 0 0 0 1.532 1.024 1.024 0 0 0 1.479 0l5.227-5.417a1.111 1.111 0 0 0 0-1.532L40.467.317a1.02 1.02 0 0 0-1.479 0 1.111 1.111 0 0 0 0 1.532l3.443 3.568H1.045C.468 5.417 0 5.902 0 6.5s.468 1.083 1.045 1.083Z"/></svg>');

    /* Chrome, still requires prefix in 2022 */
    -webkit-mask: var(--svg);

    /* Firefox and Safari */
    mask: var(--svg);

    background-color: currentColor;
    width: 46px;
    height: 13px;
    margin-right: $spacing-m;
    transform: translateY(7px);
    background-position: center right;
    transition: all 0.3s;
    margin-left: -10px;
  }
  &:hover {
    cursor: pointer;
    &::before {
      width: 46px;
      margin-right: $spacing-s;
      margin-left: 0;
      transform: translate(0, 7px);
    }
  }
}

.btn--primary,
.btn--secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  background: $color-brand-blue;
  color: $color-white;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  letter-spacing: 2px;
  text-align: center;
  text-decoration: none;
  padding: rem(13) rem(50);
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    color: $color-white;
    padding-left: rem(55);
    padding-right: rem(55);
  }

  .spinner {
    display: none;
    position: relative;
    width: rem(17);
    height: rem(17);
    margin-right: rem(10);

    &::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid rgba($color-white, 0.3);
      border-top-color: $color-white;
      animation: spinner 1s linear infinite;
    }
  }

  .waiting & {
    color: rgba($color-white, 0.3);

    .spinner {
      display: inline-block;
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &.inverted {
    color: $color-text;
    &:hover {
      color: $color-text;
    }
  }
}

.btn--secondary {
  background: $color-brand-yellow;
  color: $color-text;
  &:hover {
    cursor: pointer;
    background: darken($color-brand-yellow, 15%);
  }
}

*.inverted {
  .btnArrow {
    &__wrapper {
      @include arrowColor($color-white);
    }

    &__text {
      color: $color-white;

      &::after {
        background: $color-white;
      }
    }
  }
}

.page-honw,
.page-mag {
  .btn--primary,
  .btn--secondary {
    background: $color-brand-honw;
    &:hover {
      background: darken($color-brand-honw, 15%);
    }
  }
}

.page-wnc {
  .btn--primary,
  .btn--secondary {
    background: $color-brand-violet;
    &:hover {
      background: darken($color-brand-violet, 15%);
    }
  }
}

.page-tnx {
  .btn--primary,
  .btn--secondary {
    background: $color-brand-tnx;
    &:hover {
      background: darken($color-brand-tnx, 15%);
    }
  }
}

/*! purgecss end ignore */
